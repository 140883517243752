import React, { useEffect, useState } from "react";
import { Search, Edit, Trash2 } from "react-feather";
import { Link, useNavigate } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import Table from "../../core/pagination/datatable";
import PlusCircle from "feather-icons-react/build/IconComponents/PlusCircle";
import useFetch from "../../ApiClient/GetApi";
import { PostApi } from "../../ApiClient/PostApi";
import { error_toaster, success_toaster } from "../../Utils/Toaster";
const AdminController = () => {
    const [userData, setUserData] = useState([]);
    const [partyId, setPartyId] = useState("");
    const navigate = useNavigate();

    
    const handleShow = () => {
        navigate("/add-controller");
    };
    const { apiData, reFetch } = useFetch(`adminControllers/${partyId}`);
    useEffect(() => {
        setPartyId(localStorage.getItem("partyId"))
        if (apiData?.data?.controllers) {
            const transformedData = apiData?.data?.controllers.map((controller) => ({
                id: controller.id,
                name: controller.name,
                email: controller.email,
                countryCode: controller.countryCode,
                phoneNum: controller.phone,
                phone: controller.phone, // Assuming static values, replace with actual data if available
                role: "Controller"
            }));
            setUserData(transformedData);
        }
        
    }, [apiData,partyId]);
    const columns = [
        {
            title: "Name",
            dataIndex: "name",
            sorter: (a, b) => a.name.length - b.name.length,
        },
        {
            title: "Email",
            dataIndex: "email",
            sorter: (a, b) => a.email.length - b.email.length,
        },
        {
            title: "Phone No.",
            dataIndex: "phone",
        },
        {
            title: "Role",
            dataIndex: "role",
            sorter: (a, b) => a.role.length - b.role.length,
            render: (status) => <span className="btn btn-success btn-sm" style={{ width: "90px" }}>{status}</span>,
        },
        {
            title: "Action",
            dataIndex: "action",
            render: (text, record) => (
                <div className="action-table-data">
                    <div className="edit-delete-action">
                        <div className="input-block add-lists"></div>
                        <Link
                            to="/edit-controller"
                            state={{ id: record.id }}
                            className="confirm-text me-2 p-2"
                        >
                            <Edit className="feather-edit" />
                        </Link>
                        {/* <span style={{cursor:"pointer"}} className="me-2 p-2">
                            <Edit onClick={() => editUser(record)} className="feather-edit" />
                        </span> */}
                        <Link className="confirm-text p-2" to="#">
                            <Trash2 onClick={() => deleteUser(record.id)} className="feather-trash-2" />
                        </Link>
                    </div>
                </div>
            ),
        },
    ];
    const deleteUser = async (id) => {
        let response = await PostApi("deleteUser", { id: id });
        if (response.data.status === "1") {
            success_toaster(response.data.message);
            reFetch();
        } else {
            error_toaster(response.data.message);
        }
    };

    // const editUser = (obj) => {
    //     navigate("/edit-controller", { state: { id: obj.id } })
    // };


    return (
        <div className="page-wrapper">
            <div className="content">
                    <div className="page-header">
                        <div className="add-item d-flex">
                            <div className="page-title">
                                <h4>Admin Controller Management</h4>
                                <h6>Manage Controller</h6>
                            </div>
                        </div>
                        <ul className="table-top-head">


                        </ul>
                        <div className="page-btn">
                            <button onClick={handleShow} className="btn btn-added">
                                <PlusCircle className="me-2" />
                                Add New
                            </button>
                        </div>
                    </div>

                    {/* Product list */}
                    <div className="card table-list-card">
                        <div className="card-body">
                            <div className="table-top">
                                <div className="search-set">
                                    <div className="search-input">
                                        <input type="text" placeholder="Search" className="form-control form-control-sm formsearch" />
                                        <Link to="#" className="btn btn-searchset">
                                            <Search className="feather-search" />
                                        </Link>
                                    </div>
                                </div>

                                {/* <div className="form-sort stylewidth">
                                    <Select className="img-select" classNamePrefix="react-select" options={options} placeholder="Sort by Date" />
                                </div> */}
                            </div>
                            {/* Filter */}
                            <div className={`card`} id="filter_inputs">
                                <div className="card-body pb-0">

                                </div>
                            </div>
                            <Table columns={columns} dataSource={userData} pagination={{ pageSize: 5 }} />
                        </div>
                    </div>
                    
                </div>
         
        </div>
    );
};

export default AdminController;
