import React, { useEffect, useState, useRef } from "react";
import { Search, Edit, Trash2, Upload } from "react-feather";
import { Link } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import Table from "../../core/pagination/datatable";
import PlusCircle from "feather-icons-react/build/IconComponents/PlusCircle";
import useFetch from "../../ApiClient/GetApi";
import { error_toaster, success_toaster } from "../../Utils/Toaster";
import { PostApi } from "../../ApiClient/PostApi";
import Loader from "../../Utils/Loader";

const DistrictManagement = () => {

    const [districtData, setDistrictData] = useState([]);
    const [show, setShow] = useState(false);
    const [name, setName] = useState("");
    const [code, setCode] = useState("");
    const [type, setType] = useState("");
    const [capital, setCapital] = useState("");
    const [id, setId] = useState("");
    const [regionId, setRegionId] = useState("");
    const [loading, setLoading] = useState(false);
    const [edit, setEdit] = useState(false);
    const fileInputRef = useRef(null); // Ref for file input
    const [role, setRole] = useState(null);
    const [permissions, setPermissions] = useState([]);
    const { apiData, reFetch } = useFetch("getAllDistricts");
    console.log(apiData?.data)
    const regions = useFetch("getAllRegions");

    useEffect(() => {
        setRole(localStorage.getItem('role'));
        setPermissions(JSON.parse(localStorage.getItem('permissions')))
        if (apiData && apiData.data && apiData.data.list) {
            let formattedData = apiData.data.list.map((district) => ({
                key: district.id,
                DistrictName: district?.name,
                capital: district?.capital,
                type: district?.type,
                DistrictCode: district?.code,
                RegionName: district?.region?.name,
                regionId: district?.region?.id,
                NoOfConstituencies: district?.constituencyCount,
                NoOfPollingStations: district?.totalPollingStations,
                NoOfVoters: district?.totalVoters,
            }));


            setDistrictData(formattedData);
        }
        // Only run this effect when apiData is initially loaded or when the role/userDistrict changes
        // Ensure userDistrict is not causing unnecessary re-renders
    }, [apiData]);
    const handleClose = () => setShow(false);
    const handleShow = () => {
        setEdit(false);
        setShow(true);
        setName("");
        setCode("");
        setRegionId("");
    };


    const columns = [
        {
            title: "District Name",
            dataIndex: "DistrictName",
            sorter: (a, b) => a.DistrictName.length - b.DistrictName.length,
        },
        {
            title: "District Code",
            dataIndex: "DistrictCode",
            sorter: (a, b) => a.DistrictCode.length - b.DistrictCode.length,
        },
        {
            title: "District Type",
            dataIndex: "type",
            sorter: (a, b) => a.DistrictCode.length - b.DistrictCode.length,
        },
        {
            title: "Capital",
            dataIndex: "capital",
            sorter: (a, b) => a.DistrictCode.length - b.DistrictCode.length,
        },
        {
            title: "Region Name",
            dataIndex: "RegionName",
            sorter: (a, b) => a.RegionName.length - b.RegionName.length,
            render: (status) => <span className="btn btn-success btn-sm" style={{ width: "120px" }}>{status}</span>,
        },
        {
            title: "No. of Constituencies",
            dataIndex: "NoOfConstituencies",
            sorter: (a, b) => a.NoOfConstituencies - b.NoOfConstituencies,
        },
        // {
        //     title: "No. of Polling Stations",
        //     dataIndex: "NoOfPollingStations",
        //     sorter: (a, b) => a.NoOfPollingStations - b.NoOfPollingStations,
        //     render: (status) => <span className="btn btn-secondary btn-sm" style={{ width: "120px" }}>{status}</span>,
        // },
        // {
        //     title: "No. of Voters",
        //     dataIndex: "NoOfVoters",
        //     sorter: (a, b) => a.NoOfVoters - b.NoOfVoters,
        // },
        {
            title: "Action",
            dataIndex: "action",
            render: (text, record) => (
                <div className="action-table-data">
                    <div className="edit-delete-action">
                        {
                            role == "user" && permissions.includes("edit_district") ? <Link className="me-2 p-2">
                                <Edit onClick={() => editModal(record)} className="feather-edit" />
                            </Link> : role == "super admin" ? <Link className="me-2 p-2">
                                <Edit onClick={() => editModal(record)} className="feather-edit" />
                            </Link> : ""
                        }
                        {
                            role == "user" && permissions.includes("delete_district") ? <Link
                                className="confirm-text p-2"
                                to="#"
                                onClick={() => deleteDistrict(record.key)}
                            >
                                <Trash2 className="feather-trash-2" />
                            </Link> : role == "super admin" ? <Link
                                className="confirm-text p-2"
                                to="#"
                                onClick={() => deleteDistrict(record.key)}
                            >
                                <Trash2 className="feather-trash-2" />
                            </Link> : ""
                        }
                    </div>
                </div>
            ),
        },
    ];

    const addDistrict = async (e) => {
        e.preventDefault();
        if (name === "") {
            error_toaster("Name is required!");
            return false;
        }
        if (code === "") {
            error_toaster("Code is required!");
            return false;
        }
        if (regionId === "") {
            error_toaster("Please select region!");
            return false;
        }
        if (type === "") {
            error_toaster("Please select Type!");
            return false;
        }
        if (capital === "") {
            error_toaster("Capital is required");
            return false;
        }
        setLoading(true);
        const response = await PostApi("addDistrict", { name, code, regionId,type,capital });
        if (response.data.status === "1") {
            reFetch();
            setLoading(false);
            setShow(false);
            success_toaster(response.data.message);
        } else {
            error_toaster(response.data.message);
            setLoading(false);
        }
    };
    const updateDistrict = async (e) => {
        e.preventDefault();

        setLoading(true);
        const response = await PostApi("updateDistrict", { id, name, code, regionId,type,capital });
        console.log(response)
        if (response.data.status === "1") {
            reFetch();
            setLoading(false);
            setShow(false);
            success_toaster(response.data.message);
        } else {
            error_toaster(response.data.message);
            setLoading(false);
        }
    };

    const deleteDistrict = async (id) => {
        setLoading(true);
        const response = await PostApi("deleteDistrict", { id });
        if (response.data.status === "1") {
            reFetch();
            setLoading(false);
            setShow(false);
            success_toaster(response.data.message);
        } else {
            error_toaster(response.data.message);
            setLoading(false);
        }
    };

    const editModal = (obj) => {
        setName(obj.DistrictName);
        setId(obj.key);
        setCode(obj.DistrictCode);
        setRegionId(obj.regionId); // Assuming RegionId can be derived from RegionName
        setShow(true);
        setEdit(true);
        setType(obj.type);
        setCapital(obj.capital);
    };

    const handleFileChange = async (e) => {
        const file = e.target.files[0];
        if (!file) {
            error_toaster("Please select a file to upload.");
            return;
        }

        const formData = new FormData();
        formData.append("file", file);

        setLoading(true);
        const response = await PostApi("addDistrictFromSheet", formData, true);
        if (response.data.status === "1") {
            reFetch();
            setLoading(false);
            success_toaster(response.data.message);
        } else {
            error_toaster(response.data.message);
            setLoading(false);
        }
    };

    const handleUploadClick = () => {
        fileInputRef.current.click(); // Trigger file input click
    };

    return (
        <div className="page-wrapper">
            {loading ? <Loader /> : <div className="content">
                <div className="page-header">
                    <div className="add-item d-flex">
                        <div className="page-title">
                            <h4>District Management</h4>
                            <h6>Manage Districts</h6>
                        </div>
                    </div>
                    <ul className="table-top-head">
                        {
                            role == "super admin" ? <li>
                                <OverlayTrigger placement="top" overlay={<Tooltip id="upload-tooltip">Upload</Tooltip>}>
                                    <button className="btn btn-link" onClick={handleUploadClick}>
                                        <Upload />
                                    </button>
                                </OverlayTrigger>
                            </li> : ""
                        }



                    </ul>
                    <div className="page-btn">
                        {
                            role == "super admin" ? <button onClick={handleShow} className="btn btn-added">
                                <PlusCircle className="me-2" />
                                Add New
                            </button> : ""
                        }
                        {
                            role == "user" && permissions.includes("add_district") ? <button onClick={handleShow} className="btn btn-added">
                                <PlusCircle className="me-2" />
                                Add New
                            </button> : ""
                        }
                    </div>
                </div>

                {/* District list */}
                <div className="card table-list-card">
                    <div className="card-body">
                        <div className="table-top">
                            <div className="search-set">
                                <div className="search-input">
                                    <input type="text" placeholder="Search" className="form-control form-control-sm formsearch" />
                                    <Link to="#" className="btn btn-searchset">
                                        <Search className="feather-search" />
                                    </Link>
                                </div>
                            </div>

                        </div>
                        {/* Filter */}
                        <div className={`card `} id="filter_inputs">
                            <div className="card-body pb-0">
                                <div className="row">


                                </div>
                            </div>
                        </div>
                        <Table columns={columns} dataSource={districtData} pagination={{ pageSize: 5 }} />
                    </div>
                </div>
                {/* Hidden file input */}
                <input
                    type="file"
                    ref={fileInputRef}
                    style={{ display: "none" }}
                    accept=".xlsx, .xls"
                    onChange={handleFileChange}
                />
                {/* Add/Edit District Modal */}
                <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>{edit ? "Update District" : "Add District"}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <form className="p-2">
                            <div className="form-group">
                                <label>District Name</label>
                                <input
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter District Name"
                                />
                            </div>
                            <div className="form-group">
                                <label>District Code</label>
                                <input
                                    value={code}
                                    onChange={(e) => setCode(e.target.value)}
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter District Code"
                                />
                            </div>
                            <div className="form-group">
                                <label>Region Name</label>
                                <select
                                    value={regionId}
                                    onChange={(e) => setRegionId(e.target.value)}
                                    className="form-control"
                                >
                                    <option value="">Select Region</option>
                                    {regions?.apiData?.data?.regions?.map((dat) => (
                                        <option key={dat.id} value={dat.id}>{dat.name}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="form-group">
                                <label>District Type</label>
                                <select
                                    value={type}
                                    onChange={(e) => setType(e.target.value)}
                                    className="form-control"
                                >
                                    <option value="">Select Type</option>
                                    <option value="Municipal">Municipal</option>
                                    <option value="District">District</option>

                                </select>
                            </div>
                            <div className="form-group">
                                <label>Capital</label>
                                <input
                                    value={capital}
                                    onChange={(e) => setCapital(e.target.value)}
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter Capital name"
                                />
                            </div>

                            <div className="d-flex justify-content-center w-100 gap-3 mt-3">
                                <button type="button" className="btn btn-secondary" onClick={handleClose}>
                                    Close
                                </button>
                                {edit ? (
                                    <button onClick={updateDistrict} type="button" className="btn btn-primary">
                                        Update
                                    </button>
                                ) : (
                                    <button onClick={addDistrict} type="button" className="btn btn-primary">
                                        Save
                                    </button>
                                )}
                            </div>
                        </form>
                    </Modal.Body>
                </Modal>
            </div>}
        </div>
    );
};

export default DistrictManagement;
