import React, { useState } from "react";
import ImageWithBasePath from "../../../core/img/imagewithbasebath";
import { Link, useNavigate } from "react-router-dom";
import { all_routes } from "../../../Router/all_routes";
import { PostApi } from "../../../ApiClient/PostApi";
import { error_toaster, success_toaster } from "../../../Utils/Toaster";
import Loader from "../../../Utils/Loader";

const Forgotpassword = () => {
  const route = all_routes;
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const forgetPasswordRequest = async () => {
    try {
      setLoading(true)
      const response = await PostApi("forgetPasswordRequest", { email: email });
      console.log(response)
      if (response.data.status === "1") {
        localStorage.setItem("userId", response?.data?.data?.userId);
        localStorage.setItem("forgetRequestId", response?.data?.data?.forgetRequestId);
        success_toaster(response.data.message);
        setLoading(false);
        navigate("/reset-password");
      }
      else {
        error_toaster(response.data.message);
        setLoading(false)
      }

    }
    catch (error) {
      error_toaster("Something went wrong");
      setLoading(false);
    }
  }
  return (
    <div className="main-wrapper">

      {
        loading ? <Loader /> : <div className="account-content">
          <div className="login-wrapper forgot-pass-wrap bg-img">
            <div className="login-content">
              <form>
                <div className="login-userset">
                  <div className="login-logo logo-normal">
                    <ImageWithBasePath src="assets/img/logo.png" alt="img" />
                  </div>
                  <Link to={route.dashboard} className="login-logo logo-white">
                    <ImageWithBasePath src="assets/img/logo-white.png" alt />
                  </Link>
                  <div className="login-userheading">
                    <h3>Forgot password?</h3>
                    <h4>
                      If you forgot your password, well, then we’ll email you
                      instructions to reset your password.
                    </h4>
                  </div>
                  <div className="form-login">
                    <label>Email</label>
                    <div className="form-addons">
                      <input onChange={(e) => setEmail(e.target.value)} type="email" className="form-control" />
                      <ImageWithBasePath
                        src="assets/img/icons/mail.svg"
                        alt="img"
                      />
                    </div>
                  </div>
                  <div className="form-login">
                    <button onClick={forgetPasswordRequest} className="btn btn-login">
                      Submit
                    </button>
                  </div>
                  <div className="signinform text-center">
                    <h4>
                      Return to
                      <Link to={route.signin} className="hover-a">
                        {" "}
                        login{" "}
                      </Link>
                    </h4>
                  </div>
                 
                 
                 
                </div>
              </form>
            </div>
          </div>
        </div>
      }
    </div>
  );
};

export default Forgotpassword;
