import React, { useEffect, useState, useMemo } from "react";
import { Search, Edit } from "react-feather";
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Modal } from "react-bootstrap";
import Table from "../../core/pagination/datatable";
import PlusCircle from "feather-icons-react/build/IconComponents/PlusCircle";
import useFetch from "../../ApiClient/GetApi";
import { PostApi } from "../../ApiClient/PostApi";
import { error_toaster, success_toaster } from "../../Utils/Toaster";
import Loader from "../../Utils/Loader";

const Levels = () => {
    const [selectedDate, setSelectedDate] = useState(null);
    const [regionData, setRegionData] = useState([]);
    const [role, setRole] = useState(null);
    const [permissions, setPermissions] = useState([]);
    const [show, setShow] = useState(false);
    const [name, setName] = useState("");
    const [id, setId] = useState("");
    const [loading, setLoading] = useState(false);
    const [edit, setEdit] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => {
        setEdit(false);
        setShow(true);
        setName("");
        setId("");
    };
    const handleDateChange = (date) => {
        setSelectedDate(date);
    };

    const { apiData, reFetch } = useFetch(`getAllLevels`);

    const transformedData = useMemo(() => {
        if (apiData?.data?.levels) {
            return apiData?.data?.levels.map((region) => ({
                key: region?.id,
                name: region?.name,
                status: region?.status,
            }));
        }
        return [];
    }, [apiData]);

    useEffect(() => {
        setRole(localStorage.getItem('role'));
        setPermissions(JSON.parse(localStorage.getItem('permissions')))
        setRegionData(transformedData);
    }, [transformedData, role, permissions]);

    const editModal = (obj) => {
        setName(obj.name);
        setId(obj.key);
        setShow(true);
        setEdit(true);
    };

    const updateRegion = async () => {
        setLoading(true);
        const response = await PostApi("updateLevel", {
            id: id,
            name: name,
           
        });
        if (response.data.status === "1") {
            reFetch();
            success_toaster(response.data.message);
            setLoading(false);
            setShow(false);
        } else {
            error_toaster(response.data.message);
            setLoading(false);
        }
    };

    const addRegion = async (e) => {
        e.preventDefault();
        if (name === "") {
            error_toaster("Name is required!");
            return false;
        }
       
        setLoading(true);
        const response = await PostApi("addLevel", { name });
        if (response.data.status === "1") {
            reFetch();
            setLoading(false);
            setShow(false);
            success_toaster(response.data.message);
        } else {
            error_toaster(response.data.message);
            setLoading(false);
        }
    };
    const changeStatus = async (id, status) => {
        setLoading(true);
        const response = await PostApi("changeStatusLevel", {
            id: id,
            status: status
        });
        if (response.data.status == "1") {
            success_toaster(response.data.message);
            setLoading(false);
            reFetch();
        }
        else {
            error_toaster(response.data.message);
            setLoading(false);
        }
    }

    const columns = [
        {
            title: "Name",
            dataIndex: "name",
            sorter: (a, b) => a.RegionName.length - b.RegionName.length,
        },
        {
            title: "Current Status",
            dataIndex: "status",
            sorter: (a, b) => a.RegionCode.length - b.RegionCode.length,
            render: (status) => (
                <span style={{ width: "90px" }}>
                    {status ? "Active" : "Block"}
                </span>
            ),

        },
        {
            title: "Status Action",
            dataIndex: "status",
            sorter: (a, b) => a.RegionCode.length - b.RegionCode.length,
            render: (status, record) => (
                <span  >
                    {status ? <button onClick={() => changeStatus(record.key, false)} className="btn btn-sm btn-danger">Block</button> : <button onClick={() => changeStatus(record.key, true)} className="btn btn-sm btn-success">Active</button>}
                </span>
            ),

        },


        {
            title: "Action",
            dataIndex: "action",
            render: (text, record) => (
                <div className="action-table-data">
                    <div className="edit-delete-action">
                        {
                            role == "user" && permissions.includes("edit_region") ? <Link className="me-2 p-2">
                                <Edit onClick={() => editModal(record)} className="feather-edit" />
                            </Link> : role == "super admin" ? <Link className="me-2 p-2">
                                <Edit onClick={() => editModal(record)} className="feather-edit" />
                            </Link> : ""
                        }


                    </div>
                </div>
            ),
        },
    ];

    return (
        <div className="page-wrapper">
            {loading ? (
                <Loader />
            ) : (
                <div className="content">
                    <div className="page-header">
                        <div className="add-item d-flex">
                            <div className="page-title">
                                <h4>Designations Management</h4>
                                <h6>Manage Designations</h6>
                            </div>
                        </div>
                        <ul className="table-top-head">
                           
                        </ul>
                        <div className="page-btn">
                            {
                                role == "user" && permissions.includes("add_region") ? <button onClick={handleShow} className="btn btn-added">
                                    <PlusCircle className="me-2" />
                                    Add New
                                </button> : role == "super admin" ? <button onClick={handleShow} className="btn btn-added">
                                    <PlusCircle className="me-2" />
                                    Add New
                                </button> : ""
                            }

                        </div>
                    </div>

                    {/* Region list */}
                    <div className="card table-list-card">
                        <div className="card-body">
                            <div className="table-top">
                                <div className="search-set">
                                    <div className="search-input">
                                        <input
                                            type="text"
                                            placeholder="Search"
                                            className="form-control form-control-sm formsearch"
                                        />
                                        <Link to="#" className="btn btn-searchset">
                                            <Search className="feather-search" />
                                        </Link>
                                    </div>
                                </div>

                            </div>
                            {/* Filter */}
                            <div
                                className={`card`}
                                id="filter_inputs"
                            >
                                <div className="card-body pb-0">
                                    <div className="row">
                                        <div className="col-lg-12 col-sm-12">
                                            <div className="row">
                                                <div className="col-lg col-sm-6 col-12">
                                                    <div className="form-group">
                                                        <div className="input-groupicon">
                                                            <DatePicker
                                                                selected={selectedDate}
                                                                onChange={handleDateChange}
                                                                className="form-control datetimepicker"
                                                                placeholderText="Select Date"
                                                            />
                                                            <div className="addonset">
                                                                <img
                                                                    src="assets/img/icons/calendars.svg"
                                                                    alt="img"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg col-sm-6 col-12">
                                                    <div className="form-group">
                                                        <input
                                                            type="text"
                                                            placeholder="Enter Region Code"
                                                            className="form-control"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg col-sm-6 col-12">
                                                    <div className="form-group">
                                                        <input
                                                            type="text"
                                                            placeholder="Enter Region Name"
                                                            className="form-control"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg-1 col-sm-6 col-12 ms-auto">
                                                    <div className="form-group">
                                                        <Link
                                                            className="btn btn-filters ms-auto"
                                                            to="#"
                                                        >
                                                            <img
                                                                src="assets/img/icons/searchwhites.svg"
                                                                alt="img"
                                                            />
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* Region Table */}
                            <div className="table-responsive">
                                <Table
                                    className="table  table-hover table-center mb-0 datatable"
                                    columns={columns}
                                    dataSource={regionData}
                                    rowKey={(record) => record.key}
                                />
                            </div>
                        </div>
                    </div>
                   
                    {/* Add/Update Region Modal */}
                    <Modal show={show} onHide={handleClose}>
                        <Modal.Header closeButton>
                            <Modal.Title>
                                {edit ? "Update Designation" : "Add New Designation"}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <form className="p-2">
                                <div className="form-group">
                                    <label>Name</label>
                                    <input
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Name"
                                    />
                                </div>
                              

                                {loading ? (
                                    <div className="d-flex justify-content-center align-content-center">
                                        <Loader />
                                    </div>
                                ) : (
                                    <div className="d-flex justify-content-center gap-3 mt-3">
                                        <button
                                            type="button"
                                            className="btn btn-secondary"
                                            onClick={handleClose}
                                        >
                                            Close
                                        </button>
                                        {edit ? (
                                            <button
                                                onClick={updateRegion}
                                                type="submit"
                                                className="btn btn-primary"
                                            >
                                                Update
                                            </button>
                                        ) : (
                                            <button
                                                onClick={addRegion}
                                                type="submit"
                                                className="btn btn-primary"
                                            >
                                                Save
                                            </button>
                                        )}
                                    </div>
                                )}
                            </form>
                        </Modal.Body>
                    </Modal>
                </div>
            )}
        </div>
    );
};

export default Levels;
