import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import Table from "../../core/pagination/datatable";
import { Chart as ChartJS, ArcElement, Tooltip as ChartTooltip, Legend, CategoryScale, LinearScale, BarElement, Title } from 'chart.js';
import { PlusCircle, Upload } from "feather-icons-react/build/IconComponents";
import useFetch from "../../ApiClient/GetApi";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { PostApi } from "../../ApiClient/PostApi";
import { error_toaster, success_toaster } from "../../Utils/Toaster";
import Loader from "../../Utils/Loader";
import { toWords } from 'number-to-words'; // Import number-to-words package

ChartJS.register(ArcElement, ChartTooltip, Legend, CategoryScale, LinearScale, BarElement, Title);

const Results = () => {
    const navigate = useNavigate();
    const [showForm, setShowForm] = useState(false);
    const [consList, setConsList] = useState([]);
    const [pollingStationList, setPollingStationList] = useState([]);
    const [candidateList, setCandidateList] = useState([]);
    const [selectedCandidateType, setSelectedCandidateType] = useState(null);
    const [selectedPollingStation, setSelectedPollingStation] = useState(null);
    const [pollingStationName, setPollingStationName] = useState(null);
    const [candidates, setCandidates] = useState([]);
    const [totalVoters, setTotalVoters] = useState(null);
    const [consName, setConsName] = useState(null);
    const [distName, setDistName] = useState(null);
    const [regionName, setRegionName] = useState(null);
    const [loading, setLoading] = useState(false);
    const [rejectedVotes, setRejectedVotes] = useState(null);
    const [comment, setComment] = useState('');
    const [file, setFile] = useState(null);
    const fileInputRef = useRef(null); // Ref for file input
    const [role, setRole] = useState("");
    const [permissions, setPermissions] = useState([]);
    const [partyId, setParty] = useState("");

    const [totalVoteSecured, setTotalVoteSecured] = useState(0);

    const { apiData } = useFetch("getResultScreenData");

    useEffect(() => {
        setRole(localStorage.getItem('role'));
        setPermissions(JSON.parse(localStorage.getItem('permissions')))
        if (apiData && apiData.data && apiData.data.consistuencies) {
            setConsList(apiData.data.consistuencies)
        }

        if (role == "admin") {
            setParty(localStorage.getItem("partyId"));
        }
    }, [apiData]);


    const results = useFetch(role == "super admin" ? "getResults" : `adminPartyResult/${partyId}`);
    console.log(results)

    const handleCloseForm = () => setShowForm(false);
    const handleShowForm = () => {
        // Reset all states when opening the modal
        setShowForm(true);
        setSelectedCandidateType(null);
        setSelectedPollingStation(null);
        setCandidates([]); // Reset candidates array
        setCandidateList([]); // Reset candidateList array
        setTotalVoters(null);
        setRejectedVotes(0); // Reset rejected votes
        setComment(null)
    };

    const handleConstituencyChange = async (event) => {
        const constituencyId = parseInt(event.target.value);
        let response = await PostApi("consistuencyPollingStations", { constituencyId,selectedCandidateType });
        setPollingStationList(response.data.data.pollingstations);
        console.log(response?.data)
        const fetchedCandidates = response?.data?.data?.candidates?.map(candidate => ({
            ...candidate,
            votes: candidate?.votes || '', // Initialize votes field if it's not already present
            inwords: toWords(candidate?.votes || 0) // Add the inwords field
        }));
        setCandidateList(fetchedCandidates);
        setCandidates(fetchedCandidates);
        console.log(fetchedCandidates)
    };

    const handleCandidateTypeChange = (event) => {
        const candidateTypeId = parseInt(event.target.value);
        setSelectedCandidateType(candidateTypeId);
        setPollingStationList([]);
        setCandidateList([]);
    };

    const handlePollingStationChange = async (event) => {
        const pollingStationId = parseInt(event.target.value);
        let response = await PostApi("pollingStationCandidates", { pollingStationId, selectedCandidateType });
        console.log(response)
        setTotalVoters(response?.data?.data?.station?.totalVoters);
        setConsName(response?.data?.data?.station?.constituency?.name);
        setDistName(response?.data?.data?.station?.constituency?.district?.name);
        setRegionName(response?.data?.data?.station?.constituency?.district?.region?.name);
        setPollingStationName(response?.data?.data?.station?.name);

        // const fetchedCandidates = response?.data?.data?.candidates?.map(candidate => ({
        //     ...candidate,
        //     votes: candidate?.votes || '', // Initialize votes field if it's not already present
        //     inwords: toWords(candidate?.votes || 0) // Add the inwords field
        // }));

        // setCandidateList(fetchedCandidates);
        // setCandidates(fetchedCandidates);
        setSelectedPollingStation(pollingStationId);
    };

    const handleSave = async () => {
        if (parseInt(totalVoters) < (parseInt(rejectedVotes) + parseInt(totalVoteSecured))) {
            error_toaster("Votes polled are greater than Total Votes of polling Station");
            return false;
        }
        if (partyId == "") {
            error_toaster("Select Party");
            return false;
        }

        setLoading(true);

        // Create a FormData object
        const formData = new FormData();
        formData.append('pollingStationId', selectedPollingStation);
        formData.append('rejectedVotes', rejectedVotes);
        formData.append('comment', comment);
        formData.append('consName', consName);
        formData.append('regionName', regionName);
        formData.append('name', localStorage.getItem("name"));
        formData.append('pollingStationName', pollingStationName);
        formData.append('userId', localStorage.getItem("userId"));
        formData.append('partyId', partyId);
        formData.append('selectedCandidateType', selectedCandidateType);

        // Append file if it exists
        if (file) {
            formData.append('file', file);
        }

        // Append candidates data
        formData.append('candidates', JSON.stringify(candidates.map(candidate => ({
            id: candidate?.candidateId,
            votesSecured: candidate.votes,
            inwords: candidate.inwords
        }))));

        try {
            let response = await PostApi("saveResult", formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            if (response.data.status === "1") {
                results.reFetch();
                success_toaster(response.data.message);
                setLoading(false);
                setShowForm(false);
            } else {
                error_toaster(response.data.message);
                setLoading(false);
            }
        } catch (error) {
            console.error("Error saving result:", error);
            error_toaster("An error occurred while saving the result.");
            setLoading(false);
        }
    };


    const columns = [
        {
            title: "Polling Station",
            dataIndex: "pollingstation.name",
            key: "pollingstation",
            render: (_, record) => record?.pollingstation?.name || "No Data",
            sorter: (a, b) => (a.pollingstation?.name || "").localeCompare(b.pollingstation?.name || ""),
        },
        {
            title: "PS-Code",
            dataIndex: "pollingstation.code",
            key: "psCode",
            render: (_, record) => record?.pollingstation?.code || "No Data",
            sorter: (a, b) => (a.pollingstation?.code || "").localeCompare(b.pollingstation?.code || ""),
        },
        {
            title: "Total Voters",
            dataIndex: "totalVoters",
            key: "totalVoters",
            render: (text) => text !== null && text !== undefined ? text : "No Data",
            sorter: (a, b) => (a.totalVoters || 0) - (b.totalVoters || 0),
        },
        {
            title: "Votes Cast",
            dataIndex: "voteCasts",
            key: "voteCasts",
            render: (text) => text !== null && text !== undefined ? text : "No Data",
            sorter: (a, b) => (a.voteCasts || 0) - (b.voteCasts || 0),
        },
        {
            title: "Winner Candidate",
            dataIndex: "candidate.name",
            key: "candidate",
            render: (_, record) => (
                <span className="btn btn-success btn-sm" style={{ width: "120px" }}>
                    {record?.candidate?.name || "No Data"}
                </span>
            ),
            sorter: (a, b) => (a.candidate?.name || "").localeCompare(b.candidate?.name || ""),
        },
        {
            title: "Candidate's Votes",
            dataIndex: "winningCandidateVotes",
            key: "winningCandidateVotes",
            render: (text) => text !== null && text !== undefined ? text : "No Data",
            sorter: (a, b) => (a.winningCandidateVotes || 0) - (b.winningCandidateVotes || 0),
        },
        {
            title: "Type",
            dataIndex: "winningCandidateVotes",
            key: "winningCandidateVotes",
            render: (_, record) => record?.candidatetype?.name || "No Data",
            sorter: (a, b) => (a.candidatetype?.name || "").localeCompare(b.candidatetype?.name || ""),
        },
        {
            title: "Party",
            dataIndex: "winningCandidateVotes",
            key: "winningCandidateVotes",
            render: (_, record) => record?.party?.name || "No Data",
            sorter: (a, b) => (a?.party?.name || "").localeCompare(b?.party?.name || ""),
        },
        {
            title: "Consistuency",
            dataIndex: "winningCandidateVotes",
            key: "winningCandidateVotes",
            render: (_, record) => record?.pollingstation?.constituency?.name || "No Data",
            sorter: (a, b) => (a?.party?.name || "").localeCompare(b?.party?.name || ""),
        },
        {
            title: "District",
            dataIndex: "winningCandidateVotes",
            key: "winningCandidateVotes",
            render: (_, record) => record?.pollingstation?.constituency?.district?.name || "No Data",
            sorter: (a, b) => (a?.party?.name || "").localeCompare(b?.party?.name || ""),
        },
        {
            title: "D-Code",
            dataIndex: "winningCandidateVotes",
            key: "winningCandidateVotes",
            render: (_, record) => record?.pollingstation?.constituency?.district?.code || "No Data",
            sorter: (a, b) => (a?.party?.name || "").localeCompare(b?.party?.name || ""),
        },
        {
            title: "Region",
            dataIndex: "winningCandidateVotes",
            key: "winningCandidateVotes",
            render: (_, record) => record?.pollingstation?.constituency?.district?.region?.name || "No Data",
            sorter: (a, b) => (a?.party?.name || "").localeCompare(b?.party?.name || ""),
        },
        {
            title: "R-Code",
            dataIndex: "winningCandidateVotes",
            key: "winningCandidateVotes",
            render: (_, record) => record?.pollingstation?.constituency?.district?.region?.code || "No Data",
            sorter: (a, b) => (a?.party?.name || "").localeCompare(b?.party?.name || ""),
        },
        {
            title: "Added By",
            dataIndex: "winningCandidateVotes",
            key: "winningCandidateVotes",
            render: (_, record) => record?.user?.name || "No Data",
            sorter: (a, b) => (a?.user?.name || "").localeCompare(b?.user?.name || ""),
        },
        {
            title: "Action",
            dataIndex: "action",
            render: (_, record) => (
                <div className="action-table-data">
                    <div className="edit-delete-action">
                        <button className="me-2 p-2 btn btn-warning" to="#" onClick={() => handleShow(record)}>
                            View
                        </button>
                    </div>
                </div>
            ),
        },
    ];

    const handleShow = (obj) => {
        navigate("/result-details", { state: { id: obj.id } });
    };
    const handleRejectedVotesChange = (event) => {
        const value = parseInt(event.target.value) || 0;
        setRejectedVotes(value);

    };
    const handleVoteChange = (index, value) => {
        const newCandidates = [...candidates];
        newCandidates[index].votes = value;
        newCandidates[index].inwords = toWords(parseInt(value) || 0); // Update inwords based on the new vote value
        setCandidates(newCandidates);
        const newTotalVoteSecured = newCandidates.reduce((acc, candidate) => acc + parseInt(candidate.votes, 10), 0);

        // Update the totalVoteSecured state
        setTotalVoteSecured(newTotalVoteSecured);
    };
    const handleFileChange = async (e) => {
        const file = e.target.files[0];
        if (!file) {
            error_toaster("Please select a file to upload.");
            return;
        }

        const formData = new FormData();
        formData.append("file", file);

        setLoading(true);
        const response = await PostApi("saveResultsFromExcel", formData, true);
        if (response.data.status === "1") {
            results.reFetch();
            setLoading(false);
            success_toaster(response.data.message);
        } else {
            error_toaster(response.data.message);
            setLoading(false);
        }
    };

    const handleUploadClick = () => {
        fileInputRef.current.click(); // Trigger file input click
    };
    return (
        <div className="page-wrapper">
            <ToastContainer />
            {loading ? <Loader /> : (
                <div className="content">
                    <div className="page-header">
                        <div className="add-item d-flex">
                            <div className="page-title">
                                <h4>Results Input & Report</h4>
                                <h6>View and Analyze Results</h6>
                            </div>
                        </div>
                        <ul className="table-top-head">
                            <li>
                                <OverlayTrigger placement="top" overlay={<Tooltip id="upload-tooltip">Upload</Tooltip>}>
                                    <button className="btn btn-link" onClick={handleUploadClick}>
                                        <Upload />
                                    </button>
                                </OverlayTrigger>
                            </li>
                        </ul>
                        {
                            role == "super admin" || role == "admin" ? <div className="page-btn">
                                <button onClick={handleShowForm} className="btn btn-added">
                                    <PlusCircle className="me-2" />
                                    Add New
                                </button>
                            </div> : ""
                        }
                        {
                            (role == "user" && permissions.includes('add_result')) && <button onClick={handleShowForm} className="btn btn-added">
                                <PlusCircle className="me-2" />
                                Add New
                            </button>
                        }
                    </div>

                    <div className="card table-list-card">
                        <div className="card-body"></div>
                        <Table columns={columns} dataSource={results.apiData?.data?.results || []} rowKey="id" />
                    </div>
                    <input
                        type="file"
                        ref={fileInputRef}
                        style={{ display: "none" }}
                        accept=".xlsx, .xls"
                        onChange={handleFileChange}
                    />
                    <Modal show={showForm} onHide={handleCloseForm} centered size="xl" className="custom-modal">
                        <Modal.Header closeButton className="bg-primary text-white">
                            <Modal.Title>Add New Result</Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="bg-light">
                            <form>
                                <div className="row">
                                    {
                                        role == "super admin" && <div className="col-md-3 mt-3">
                                            <div className="form-group">
                                                <label className="form-label">Party</label>
                                                <select className="form-control" onChange={(e) => setParty(e.target.value)}>
                                                    <option value="">Select Party</option>
                                                    {apiData?.data?.parties?.map((part) => (
                                                        <option key={part.id} value={part.id}>
                                                            {part.name}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    }
                                    <div className="col-md-3 mt-3">
                                        <div className="form-group">
                                            <label className="form-label">Candidate Type</label>
                                            <select className="form-control" onChange={handleCandidateTypeChange}>
                                                <option value="">Select Candidate Type</option>
                                                {apiData?.data?.types?.map((type) => (
                                                    <option key={type.id} value={type.id}>
                                                        {type.name}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>

                                    {consList?.length > 0 && (
                                        <div className="col-md-3 mt-3">
                                            <div className="form-group">
                                                <label className="form-label">Constituency</label>
                                                <select className="form-control" onChange={handleConstituencyChange}>
                                                    <option value="">Select Constituency</option>
                                                    {consList.map((cons) => (
                                                        <option key={cons.id} value={cons.id}>
                                                            {cons.name}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    )}

                                    {pollingStationList?.length > 0 && (
                                        <div className="col-md-3 mt-3">
                                            <div className="form-group">
                                                <label className="form-label">Polling Station</label>
                                                <select className="form-control" onChange={handlePollingStationChange}>
                                                    <option value="">Select Polling Station</option>
                                                    {pollingStationList.map((ps) => (
                                                        <option key={ps.id} value={ps.id}>
                                                            {ps.name}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    )}

                                    {selectedPollingStation && (
                                        <div className="col-md-12 mt-3">
                                            <div className="row">
                                                <div className="col-md-3">
                                                    <div className="card mb-3 shadow-sm bg-primary text-white">
                                                        <div className="card-body">
                                                            <h5 className="card-title">Total Voters</h5>
                                                            <p className="card-text">{totalVoters}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                {consName && (
                                                    <div className="col-md-3">
                                                        <div className="card mb-3 shadow-sm bg-success text-white">
                                                            <div className="card-body">
                                                                <h5 className="card-title">Constituency</h5>
                                                                <p className="card-text">{consName}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                                {distName && (
                                                    <div className="col-md-3">
                                                        <div className="card mb-3 shadow-sm bg-warning text-dark">
                                                            <div className="card-body">
                                                                <h5 className="card-title">District</h5>
                                                                <p className="card-text">{distName}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                                {regionName && (
                                                    <div className="col-md-3">
                                                        <div className="card mb-3 shadow-sm bg-danger text-white">
                                                            <div className="card-body">
                                                                <h5 className="card-title">Region</h5>
                                                                <p className="card-text">{regionName}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>

                                            <h5 className="my-4">Candidates List</h5>
                                            {candidateList?.length > 0 && (
                                                <div className="candidate-list">
                                                    {candidateList?.map((candidate, index) => (
                                                        <div key={index} className="candidate-row d-flex align-items-center p-3 mb-3 shadow-sm border rounded bg-light">
                                                            <div className="form-group flex-fill me-3">
                                                                <label className="form-label">Candidate Name</label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    value={candidate?.candidate?.name}
                                                                    readOnly
                                                                    style={{ backgroundColor: "#e9ecef" }}
                                                                />
                                                            </div>
                                                            <div className="form-group flex-fill me-3">
                                                                <label className="form-label">Party</label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    value={candidate?.candidate?.party?.name}
                                                                    readOnly
                                                                    style={{ backgroundColor: "#e9ecef" }}
                                                                />
                                                            </div>
                                                            <div className="form-group flex-fill me-3">
                                                                <label className="form-label">No. of Votes Secured</label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    style={{background:"#FFFF8F"}}
                                                                    value={candidates[index].votes}
                                                                    onChange={(e) => handleVoteChange(index, e.target.value)}
                                                                />
                                                            </div>
                                                            <div className="form-group flex-fill me-3">
                                                                <label className="form-label">In Words</label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    value={candidates[index].inwords}
                                                                    readOnly
                                                                    style={{ backgroundColor: "#e9ecef" }}
                                                                />
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            )}

                                            {/* Additional Sections */}
                                            <div className="mt-4">
                                                <div className="form-group">
                                                    <label className="form-label">Rejected Votes</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        style={{background:"#FFFF8F"}}
                                                        placeholder="Enter number of rejected votes"
                                                        value={rejectedVotes}
                                                        onChange={handleRejectedVotesChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className="mt-4 text-black">
                                                <div className="form-group">Total Votes polled : {parseInt(rejectedVotes) + parseInt(totalVoteSecured)}</div>
                                            </div>

                                            <div className="mt-4">
                                                <div className="form-group">
                                                    <label className="form-label">File Attachment</label>
                                                    <input
                                                        type="file"
                                                        className="form-control"
                                                        onChange={(e) => setFile(e.target.files[0])}
                                                    />
                                                </div>
                                            </div>

                                            <div className="mt-4">
                                                <div className="form-group">
                                                    <label className="form-label">Comments</label>
                                                    <textarea
                                                        className="form-control"
                                                        rows="4"
                                                        placeholder="Add your comments here"
                                                        value={comment}
                                                        onChange={(e) => setComment(e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </form>
                        </Modal.Body>
                        <Modal.Footer>
                            <button onClick={handleCloseForm} className="btn btn-secondary">
                                Close
                            </button>
                            <button onClick={handleSave} className="btn btn-primary">
                                Save Changes
                            </button>
                        </Modal.Footer>
                    </Modal>





                </div>
            )}
        </div>
    );
};

export default Results;
