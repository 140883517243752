import React, { useEffect, useRef, useState } from "react";
import { Search } from "react-feather";
import { Link } from "react-router-dom";
import Select from "react-select";
import "react-datepicker/dist/react-datepicker.css";
import { Modal } from "react-bootstrap";
import Table from "../../core/pagination/datatable";
import useFetch from "../../ApiClient/GetApi";
import { PostApi } from "../../ApiClient/PostApi";
import { error_toaster, success_toaster } from "../../Utils/Toaster";
import Loader from "../../Utils/Loader";

const ControllerAgent = () => {
    const [role, setRole] = useState("");
    const [controllerPartyId, setControllerPartyId] = useState("");
    const [controllerId, setControllerId] = useState("");
    const [permissions, setPermissions] = useState([]);
    const [userData, setUserData] = useState([]);
    const [show, setShow] = useState(false);
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [password, setPassword] = useState("");
    const [consId, setConsId] = useState("");
    const [loading, setLoading] = useState(false);
    const [partyId, setPartyId] = useState("");
    const [selectedPollingStations, setSelectedPollingStations] = useState([]);
    const [pollingStationsOptions, setPollingStationsOptions] = useState([]);
    const fileInputRef = useRef(null); // Ref for file input

    useEffect(() => {
        setControllerPartyId(localStorage.getItem("partyId"));
        setControllerId(localStorage.getItem("userId"));
    }, []);

    const handleClose = () => setShow(false);

    const { apiData: agentsData, reFetch } = useFetch(`getControllerAgents/${controllerPartyId}/${controllerId}`);
    const { apiData: getAllConsistuencies } = useFetch("getAllConsistuencies");
    const { apiData: getAllParties } = useFetch("getAllParties");

    const changeConsistuency = async (id) => {
        setConsId(id);
        try {
            let response = await PostApi("consistuencyPollingStationsForAgents", { constituencyId: id });
            if (response.data.status === "1") {
                const formattedOptions = response?.data?.data?.pollingstations?.map(station => ({
                    value: station.id,
                    label: station.name
                })) || [];
                setPollingStationsOptions(formattedOptions);
            } else {
                error_toaster(response.data.message);
            }
        } catch (error) {
            error_toaster(error.message);
        }
    };

    useEffect(() => {
        setRole(localStorage.getItem('role'));
        setPermissions(JSON.parse(localStorage.getItem('permissions')));
        if (agentsData?.data?.list) {
            const transformedData = agentsData.data.list.map((region) => {
                const user = region?.user || {};
                const pollingStationNames = user.userpollingstations?.map(station => station?.pollingstation?.name).join(', ') || '';

                return {
                    id: user.id,
                    name: user.name || 'Unknown',
                    email: user.email || 'N/A',
                    phone: user.phone || 'N/A',
                    stations: pollingStationNames,
                    role: "Agent",
                    pollingStations: user.userpollingstations?.map(station => {
                        const pollingStation = station?.pollingstation || {};
                        return {
                            value: pollingStation.id || '',
                            label: pollingStation.name || 'Unknown'
                        };
                    }) || []
                };
            });
            setUserData(transformedData);
        }
    }, [agentsData, role, permissions, controllerId, controllerPartyId]);

    const columns = [
        {
            title: "Name",
            dataIndex: "name",
            sorter: (a, b) => a.name.length - b.name.length,
        },
        {
            title: "Email",
            dataIndex: "email",
            sorter: (a, b) => a.email.length - b.email.length,
        },
        {
            title: "Phone No.",
            dataIndex: "phone",
        },
        {
            title: "Polling Stations",
            dataIndex: "stations",
            sorter: (a, b) => a.stations.length - b.stations.length,
        },
    ];

    const handleFileChange = async (e) => {
        const file = e.target.files[0];
        if (!file) {
            error_toaster("Please select a file to upload.");
            return;
        }

        const formData = new FormData();
        formData.append("file", file);

        setLoading(true);
        try {
            const response = await PostApi("addAgentsFromExcel", formData, true);
            if (response.data.status === "1") {
                reFetch();
                success_toaster(response.data.message);
            } else {
                error_toaster(response.data.message);
            }
        } catch (error) {
            error_toaster(error.message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="page-wrapper">
            {
                loading ? <Loader /> : <div className="content">
                    <div className="page-header">
                        <div className="add-item d-flex">
                            <div className="page-title">
                                <h4>Agent Management</h4>
                                <h6>Manage Agents</h6>
                            </div>
                        </div>
                    </div>

                    <div className="card table-list-card">
                        <div className="card-body">
                            <div className="table-top">
                                <div className="search-set">
                                    <div className="search-input">
                                        <input type="text" placeholder="Search" className="form-control form-control-sm formsearch" />
                                        <Link to="#" className="btn btn-searchset">
                                            <Search className="feather-search" />
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className={`card`} id="filter_inputs">
                                <div className="card-body pb-0">
                                    <div className="row"></div>
                                </div>
                            </div>
                            <Table columns={columns} dataSource={userData} pagination={{ pageSize: 5 }} />
                        </div>
                    </div>
                    <input
                        type="file"
                        ref={fileInputRef}
                        style={{ display: "none" }}
                        accept=".xlsx, .xls"
                        onChange={handleFileChange}
                    />
                    <Modal show={show} onHide={handleClose} size="lg" className="modal-styled">
                        <Modal.Header closeButton className="bg-primary text-white"></Modal.Header>
                        <Modal.Body className="p-4">
                            <form className="p-2">
                                <div className="form-group mb-3">
                                    <label className="form-label">Name</label>
                                    <input
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                        type="text"
                                        className="form-control shadow-sm"
                                        placeholder="Enter Name"
                                    />
                                </div>
                                <div className="form-group mb-3">
                                    <label className="form-label">Email</label>
                                    <input
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        type="email"
                                        className="form-control shadow-sm"
                                        placeholder="Enter Email"
                                    />
                                </div>
                                <div className="form-group mb-3">
                                    <label className="form-label">Phone No.</label>
                                    <input
                                        value={phone}
                                        onChange={(e) => setPhone(e.target.value)}
                                        type="text"
                                        className="form-control shadow-sm"
                                        placeholder="Enter Phone Number"
                                    />
                                </div>
                                <div className="form-group mb-3">
                                    <label className="form-label">Password</label>
                                    <input
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        type="password"
                                        className="form-control shadow-sm"
                                        placeholder="*******"
                                    />
                                </div>
                                <div className="form-group mb-3">
                                    <label className="form-label">Select Party</label>
                                    <select
                                        value={partyId}
                                        onChange={(e) => setPartyId(e.target.value)}
                                        className="form-select shadow-sm"
                                    >
                                        <option value="">Select Party</option>
                                        {getAllParties?.data?.parties?.map((cons) => (
                                            <option key={cons.id} value={cons.id}>
                                                {cons?.name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className="form-group mb-3">
                                    <label className="form-label">Select Constituency</label>
                                    <select
                                        value={consId}
                                        onChange={(e) => changeConsistuency(e.target.value)}
                                        className="form-select shadow-sm"
                                    >
                                        <option value="">Select Constituency</option>
                                        {getAllConsistuencies?.data?.consistuencies?.map((cons) => (
                                            <option key={cons.id} value={cons.id}>
                                                {cons?.name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className="form-group mb-3">
                                    <label className="form-label">Polling Stations</label>
                                    <Select
                                        isMulti
                                        value={selectedPollingStations}
                                        onChange={setSelectedPollingStations}
                                        options={pollingStationsOptions}
                                        className="react-select-container shadow-sm"
                                        classNamePrefix="react-select"
                                    />
                                </div>
                                <div className="d-flex justify-content-center gap-3 mt-4">
                                    <button type="button" className="btn btn-secondary shadow-sm" onClick={handleClose}>
                                        Close
                                    </button>
                                </div>
                            </form>
                        </Modal.Body>
                    </Modal>
                </div>
            }
        </div>
    );
};

export default ControllerAgent;