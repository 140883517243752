import React, { useEffect, useState, useRef } from "react";
import { Search } from "react-feather";
import { Link } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import Table from "../../core/pagination/datatable";
import useFetch from "../../ApiClient/GetApi";
const AgentPollingStations = () => {
  
    const [pollingData, setPollingData] = useState([]);
    const fileInputRef = useRef(null); 
    const { apiData } = useFetch(`getPollingStations/${localStorage.getItem('userId')}`);


    useEffect(() => {
        if (apiData && apiData.data && apiData.data.stations) {
            let formattedData = apiData.data.stations.map((station) => ({
                key: station.id,
                PollingStationName: station.name,
                PollingStationCode: station.code,
                constituencyName: station.constituency?.name,
                areaId: station.constituency?.id,
                NoOfVoters: station.totalVoters,
               
                Year: station.year,
            }));

           
            setPollingData(formattedData);
        }
    }, [apiData]);

    const columns = [
        {
            title: "Polling Station Name",
            dataIndex: "PollingStationName",
            sorter: (a, b) => a.PollingStationName.length - b.PollingStationName.length,
        },
        {
            title: "Polling Station Code",
            dataIndex: "PollingStationCode",
            sorter: (a, b) => a.PollingStationCode.length - b.PollingStationCode.length,
            render: (status) => <span className="btn btn-success btn-sm" style={{ width: "90px" }}>{status}</span>,
        },
        {
            title: "Consistuency",
            dataIndex: "constituencyName",
            sorter: (a, b) => a.AreaName.length - b.AreaName.length,
            render: (status) => <span className="btn btn-info btn-sm" style={{ width: "90px" }}>{status}</span>,
        },
        {
            title: "No. of Voters",
            dataIndex: "NoOfVoters",
            sorter: (a, b) => a.NoOfVoters - b.NoOfVoters,
        },
       
      
     
    ];


    return (
        <div className="page-wrapper">
            <div className="content">
                <div className="page-header">
                    <div className="add-item d-flex">
                        <div className="page-title">
                            <h4>Polling Station Management</h4>
                            <h6>Manage Polling Stations</h6>
                        </div>
                    </div>
                </div>

                {/* Polling Station list */}
                <div className="card table-list-card">
                    <div className="card-body">
                        <div className="table-top">
                            <div className="search-set">
                                <div className="search-input">
                                    <input type="text" placeholder="Search" className="form-control form-control-sm formsearch" />
                                    <Link to="#" className="btn btn-searchset">
                                        <Search className="feather-search" />
                                    </Link>
                                </div>
                            </div>
                           
                           
                        </div>
                        
                     
                        <Table columns={columns} dataSource={pollingData} pagination={{ pageSize: 5 }} />
                    </div>
                </div>
                {/* Hidden file input */}
                <input
                    type="file"
                    ref={fileInputRef}
                    style={{ display: "none" }}
                    accept=".xlsx, .xls"
                   
                />
               
            </div>
        </div>
    );
};

export default AgentPollingStations;
