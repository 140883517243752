import React, { useState, useEffect, useRef } from "react";
import { Search, Edit, Trash2, Upload, Eye } from "react-feather";
import { Link } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import Table from "../../core/pagination/datatable";
import PlusCircle from "feather-icons-react/build/IconComponents/PlusCircle";
import { error_toaster, success_toaster } from "../../Utils/Toaster";
import Loader from "../../Utils/Loader";
import useFetch from "../../ApiClient/GetApi";
import { PostApi } from "../../ApiClient/PostApi";
import { IMAGE_URL } from "../../Utils/urls";

const AdminParty = () => {
    const [partyData, setPartyData] = useState([]);
    const [userId,setUserId] = useState("");
    const [show, setShow] = useState(false);
    const [candidates, setCandidates] = useState([]);
    const [candidateShow, setCandidateShow] = useState(false);
    const [name, setName] = useState("");
    const [id, setId] = useState("");
    const [can, setCan] = useState("");
    const [image, setImage] = useState(""); // Add image state
    const [loading, setLoading] = useState(false);
    const [edit, setEdit] = useState(false);
    const fileInputRef = useRef(null);
    const { apiData, reFetch } = useFetch(`getAdminParty/${userId}`);
    const [role, setRole] = useState(null);
    const [permissions, setPermissions] = useState([]);

    useEffect(() => {
        setUserId(localStorage.getItem("userId"));
        setRole(localStorage.getItem('role'));
        setPermissions(JSON.parse(localStorage.getItem('permissions')))
        if (apiData && apiData.data && apiData.data.parties) {
            const formattedData = apiData.data.parties.map((party) => ({
                key: party.id,
                PartyName: party.name,
                presidentialCandidate: party?.presidentialcandidate?.candidate?.name ? party?.presidentialcandidate?.candidate?.name : "No Candidate",
                image: party.image, // Add image data
                CreatedDate: new Date(party.createdAt).toLocaleDateString(),
            }));
            setPartyData(formattedData);
        }
    }, [apiData, role, permissions]);

    const columns = [
        {
            title: "Party Name",
            dataIndex: "PartyName",
            sorter: (a, b) => a.PartyName.length - b.PartyName.length,
            render: (status) => <span className="btn btn-info btn-sm" style={{ width: "120px" }}>{status}</span>,
        },
        {
            title: "Presidential Candidate",
            dataIndex: "presidentialCandidate",
            sorter: (a, b) => a.PartyName.length - b.PartyName.length,
            render: (status) => <span className="btn btn-warning btn-sm" style={{ width: "120px" }}>{status}</span>,
        },
        {
            title: "Image",
            dataIndex: "image",
            render: (image) => image ? <img src={IMAGE_URL + image} alt="party" style={{ width: "50px", height: "50px", objectFit: "cover" }} /> : "No Image",
        },
        {
            title: "Created Date",
            dataIndex: "CreatedDate",
            sorter: (a, b) => new Date(a.CreatedDate) - new Date(b.CreatedDate),
        },
        {
            title: "Action",
            dataIndex: "action",
            render: (text, record) => (
                <div className="action-table-data">
                    <div className="edit-delete-action">
                        <Link className="me-2 p-2" onClick={() => showModal(record)} to="#">
                            <Eye className="feather-edit" />
                        </Link>
                        {
                            role == "user" && permissions.includes("edit_party") ? <Link className="me-2 p-2">
                                <Edit onClick={() => editModal(record)} className="feather-edit" />
                            </Link> : role == "super admin"  ? <Link className="me-2 p-2">
                                <Edit onClick={() => editModal(record)} className="feather-edit" />
                            </Link> : ""
                        }
                        {
                            role == "user" && permissions.includes("delete_party") ? <Link
                                className="confirm-text p-2"
                                to="#"
                                onClick={() => deleteParty(record.key)}
                            >
                                <Trash2 className="feather-trash-2" />
                            </Link> : role == "super admin"  ? <Link
                                className="confirm-text p-2"
                                to="#"
                                onClick={() => deleteParty(record.key)}
                            >
                                <Trash2 className="feather-trash-2" />
                            </Link> : ""
                        }
                    </div>
                </div>
            ),
        },
    ];

    const handleClose = () => setShow(false);
    const handleShow = () => {
        setEdit(false);
        setShow(true);
        setName("");
        setImage(""); // Reset image on show
    };

    const addParty = async (e) => {
        e.preventDefault();
        try {
            if (name === "") {
                error_toaster("All fields are required!");
                return;
            }

            const formData = new FormData();
            formData.append('name', name);

            if (image) {
                formData.append('image', image); // Ensure field name matches multer configuration
            }

            console.log("FormData content:");
            for (let pair of formData.entries()) {
                console.log(`${pair[0]}: ${pair[1]}`);
            }

            const response = await PostApi("/addParty", formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            console.log("API response:", response);

            if (response.data.status === "1") {
                success_toaster(response.data.message);
                setLoading(false);
                reFetch();
                setShow(false);
            } else {
                error_toaster(response.data.message);
                setLoading(false);
            }
        } catch (error) {
            console.error("Error during file upload:", error);
            error_toaster(error.message);
            setLoading(false);
        }
    };
    const addPresidentailCandidate = async (e) => {
        e.preventDefault();
        try {
            if (can === "") {
                error_toaster("All fields are required!");
                return;
            }
            const response = await PostApi("/addPresidentailCandidate", { partyId: id, candidateId: can });
            if (response.data.status === "1") {
                success_toaster(response.data.message);
                setLoading(false);
                reFetch();
                setShow(false);
                setCandidateShow(false);
            } else {
                error_toaster(response.data.message);
                setLoading(false);
            }
        } catch (error) {
            console.error("Error during file upload:", error);
            error_toaster(error.message);
            setLoading(false);
        }
    };


    const updateParty = async (e) => {
        e.preventDefault();
        if (name === "") {
            error_toaster("All fields are required!");
            return;
        }
        let formData = new FormData();
        formData.append('id', id);
        formData.append('name', name);
        formData.append('image', image);
        let response = await PostApi("updateParty", formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        if (response.data.status == "1") {
            success_toaster(response.data.message);
            setLoading(false);
            reFetch();
            setShow(false);
        }
        else {
            error_toaster(response.data.message);
            setLoading(false);
        }

    };

    const deleteParty = async (id) => {
        let response = await PostApi("deleteParty", { id });
        if (response.data.status == "1") {
            success_toaster(response.data.message);
            reFetch();
        }
        else {
            error_toaster(response.data.message);
        }
    };

    const editModal = (party) => {
        setLoading(false);
        setName(party.PartyName);
        setId(party.key);
        setImage(party.image); // Set image on edit
        setShow(true);
        setEdit(true);
    };
    const showModal = async (party) => {
        setId(party.key);
        const response = await PostApi("partyCandidates", { partyId: party.key });
        console.log(response?.data?.data?.candidates)
        setCandidates(response?.data?.data?.candidates)
        setCandidateShow(true);
    };

    const closeModal = () => {
        setCandidateShow(false);
        setId("");
    }

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (!file) {
            error_toaster("Please select a file to upload.");
            return;
        }
        setImage(file); // Set image file
        success_toaster("File uploaded successfully!");
    };

    const handleUploadClick = () => {
        fileInputRef.current.click();
    };

    return (
        <div className="page-wrapper">
            {loading ? (
                <Loader />
            ) : (
                <div className="content">
                    <div className="page-header">
                        <div className="add-item d-flex">
                            <div className="page-title">
                                <h4>Party Management</h4>
                                <h6>Manage Parties</h6>
                            </div>
                        </div>
                        <ul className="table-top-head">
                           {
                            role == "super admin"? <li>
                            <OverlayTrigger placement="top" overlay={<Tooltip id="upload-tooltip">Upload</Tooltip>}>
                                <button className="btn btn-link" onClick={handleUploadClick}>
                                    <Upload />
                                </button>
                            </OverlayTrigger>
                        </li>:""
                           }
                        </ul>
                        <div className="page-btn">
                          {
                            role == "super admin"?  <button onClick={handleShow} className="btn btn-added">
                            <PlusCircle className="me-2" />
                            Add New
                        </button>:""
                          }
                        </div>
                    </div>

                    <div className="card table-list-card">
                        <div className="card-body">
                            <div className="table-top">
                                <div className="search-set">
                                    <div className="search-input">
                                        <input type="text" placeholder="Search" className="form-control form-control-sm formsearch" />
                                        <Link to="#" className="btn btn-searchset">
                                            <Search className="feather-search" />
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <Table columns={columns} dataSource={partyData} pagination={{ pageSize: 5 }} />
                        </div>
                    </div>

                    <input
                        type="file"
                        ref={fileInputRef}
                        style={{ display: "none" }}
                        accept=".jpg, .jpeg, .png"
                        onChange={handleFileChange}
                    />

                    <Modal show={show} onHide={handleClose}>
                        <Modal.Header closeButton>
                            <Modal.Title>{edit ? "Update Party" : "Add Party"}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <form className="p-2">
                                <div className="form-group">
                                    <label>Party Name</label>
                                    <input
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Party Name"
                                    />
                                </div>
                                <div className="form-group">
                                    <label>Image</label>
                                    <input
                                        type="file"

                                        onChange={(e) => {
                                            setImage(e.target.files[0])
                                        }}
                                        className="form-control"
                                    />
                                    {image && <img src={URL.createObjectURL(image)} alt="Preview" style={{ width: "100px", height: "100px", objectFit: "cover", marginTop: "10px" }} />}
                                </div>
                            </form>
                        </Modal.Body>
                        <Modal.Footer>
                            <div className="d-flex justify-content-center w-100 gap-3">
                                <button type="button" className="btn btn-secondary" onClick={handleClose}>
                                    Close
                                </button>
                                {edit ? (
                                    <button onClick={updateParty} type="button" className="btn btn-primary">
                                        Update
                                    </button>
                                ) : (
                                    <button onClick={addParty} type="button" className="btn btn-primary">
                                        Save
                                    </button>
                                )}
                            </div>
                        </Modal.Footer>
                    </Modal>
                    <Modal show={candidateShow} onHide={closeModal}>
                        <Modal.Header closeButton>
                            <Modal.Title>Select Presidential Candidate</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <form className="p-2">
                                <div className="form-group">
                                    <label>Select Candidate</label>
                                    <select onChange={(e) => setCan(e.target.value)} className="form-control shadow-sm" name="" id="">
                                        <option>Select Candidate</option>
                                        {
                                            candidates?.map((can) => (
                                                <option value={can.id} key={can.id}>{can.name}</option>
                                            ))
                                        }
                                    </select>

                                </div>

                            </form>
                        </Modal.Body>
                        <Modal.Footer>
                            <div className="d-flex justify-content-center w-100 gap-3">
                                <button type="button" className="btn btn-secondary" onClick={closeModal}>
                                    Close
                                </button>

                                <button onClick={addPresidentailCandidate} type="button" className="btn btn-primary">
                                    Save
                                </button>

                            </div>
                        </Modal.Footer>
                    </Modal>
                </div>
            )}
        </div>
    );
};

export default AdminParty;
