import React from "react";
import PropTypes from "prop-types";

import { LoginCheck } from "./AuthCheck";

export default function ProtectedRoutes(props) {
  LoginCheck();

  // const { Component, requiredPermission } = props;
  const { Component } = props;

  // Retrieve the permissions from localStorage
  // const permissions = JSON.parse(localStorage.getItem("permissions")) || [];

  // Check if the user has the required permission
  // if (!permissions.includes(requiredPermission)) {
  //   return <div className="d-flex justify-content-center" style={{marginTop:"10%",fontSize:"24px"}}>
  //     <div>Access Denied</div>
  //   </div>// Redirect to a "403 Forbidden" page if permission is denied
  // }

  return (
    <>
      <Component />
    </>
  );
}

// Define prop types
ProtectedRoutes.propTypes = {
  Component: PropTypes.elementType.isRequired,
  requiredPermission: PropTypes.string.isRequired,
};
