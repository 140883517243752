import React, { useEffect, useState } from "react";
import { Filter, Search, Trash2, RotateCcw } from "react-feather";
import { Link } from "react-router-dom";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import Table from "../../core/pagination/datatable";

import PlusCircle from "feather-icons-react/build/IconComponents/PlusCircle";

const Ballot = () => {
  
    const [isFilterVisible, setIsFilterVisible] = useState(false);
    const [selectedDate, setSelectedDate] = useState(null);
    const [ballotData, setBallotData] = useState([]);
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const toggleFilterVisibility = () => {
        setIsFilterVisible((prevVisibility) => !prevVisibility);
    };

    const handleDateChange = (date) => {
        setSelectedDate(date);
    };

    const generateRandomData = () => {
        const ballotNames = ["Ballot 1", "Ballot 2", "Ballot 3", "Ballot 4"];
        const statuses = ["Open", "Closed", "In Progress"];
        const totalVotes = [100, 200, 300, 400];
        const regions = ["Region 1", "Region 2", "Region 3", "Region 4"];

        return Array.from({ length: 10 }, (_, i) => ({
            key: i,
            BallotName: ballotNames[i % ballotNames.length],
            Status: statuses[Math.floor(Math.random() * statuses.length)],
            TotalVotes: totalVotes[Math.floor(Math.random() * totalVotes.length)],
            Region: regions[Math.floor(Math.random() * regions.length)],
            CreatedDate: new Date().toISOString().split('T')[0],
        }));
    };

    useEffect(() => {
        setBallotData(generateRandomData());
    }, []);

    const getStatusStyle = (status) => {
        switch (status) {
            case "Open":
                return "badge badge-success";
            case "Closed":
                return "badge badge-danger";
            case "In Progress":
                return "badge badge-warning";
            default:
                return "badge badge-secondary";
        }
    };

    const options = [
        { value: "sortByDate", label: "Sort by Date" },
        { value: "140923", label: "14 09 23" },
        { value: "110923", label: "11 09 23" },
    ];

    const columns = [
        {
            title: "Ballot Name",
            dataIndex: "BallotName",
            sorter: (a, b) => a.BallotName.length - b.BallotName.length,
        },
        {
            title: "Status",
            dataIndex: "Status",
            render: (status) => <span className={getStatusStyle(status)}>{status}</span>,
            sorter: (a, b) => a.Status.length - b.Status.length,
        },
        {
            title: "Total Votes",
            dataIndex: "TotalVotes",
            sorter: (a, b) => a.TotalVotes - b.TotalVotes,
        },
        {
            title: "Region",
            dataIndex: "Region",
            sorter: (a, b) => a.Region.length - b.Region.length,
        },
        {
            title: "Created Date",
            dataIndex: "CreatedDate",
            sorter: (a, b) => new Date(a.CreatedDate) - new Date(b.CreatedDate),
        },
        {
            title: "Action",
            dataIndex: "action",
            render: () => (
                <div className="action-table-data">
                    <div className="edit-delete-action">
                        <Link className="me-2 p-2" to="#" data-bs-toggle="modal" data-bs-target="#view-ballot">
                            View
                        </Link>
                        <Link className="me-2 p-2" to="#">
                            Edit
                        </Link>
                        <Link className="confirm-text p-2" to="#">
                            <Trash2 className="feather-trash-2" />
                        </Link>
                    </div>
                </div>
            ),
        },
    ];

    return (
        <div className="page-wrapper">
            <div className="content">
                <div className="page-header">
                    <div className="add-item d-flex">
                        <div className="page-title">
                            <h4>Ballot Management</h4>
                            <h6>Manage E-Ballots</h6>
                        </div>
                    </div>
                    <ul className="table-top-head">
                        <li>
                            <OverlayTrigger placement="top" overlay={<Tooltip id="pdf-tooltip">Pdf</Tooltip>}>
                                <Link to="#">
                                    <img src="assets/img/icons/pdf.svg" alt="img" />
                                </Link>
                            </OverlayTrigger>
                        </li>
                        <li>
                            <OverlayTrigger placement="top" overlay={<Tooltip id="excel-tooltip">Excel</Tooltip>}>
                                <Link to="#">
                                    <img src="assets/img/icons/excel.svg" alt="img" />
                                </Link>
                            </OverlayTrigger>
                        </li>
                        <li>
                            <OverlayTrigger placement="top" overlay={<Tooltip id="printer-tooltip">Printer</Tooltip>}>
                                <Link to="#">
                                    <i data-feather="printer" className="feather-printer" />
                                </Link>
                            </OverlayTrigger>
                        </li>
                        <li>
                            <OverlayTrigger placement="top" overlay={<Tooltip id="refresh-tooltip">Refresh</Tooltip>}>
                                <Link to="#">
                                    <RotateCcw />
                                </Link>
                            </OverlayTrigger>
                        </li>
                       
                    </ul>
                    <div className="page-btn">
                        <button onClick={handleShow} className="btn btn-added">
                            <PlusCircle className="me-2" />
                            Add New
                        </button>
                    </div>
                </div>

                {/* Ballot list */}
                <div className="card table-list-card">
                    <div className="card-body">
                        <div className="table-top">
                            <div className="search-set">
                                <div className="search-input">
                                    <input type="text" placeholder="Search" className="form-control form-control-sm formsearch" />
                                    <Link to="#" className="btn btn-searchset">
                                        <Search className="feather-search" />
                                    </Link>
                                </div>
                            </div>
                            <div className="search-path">
                                <Link className={`btn btn-filter ${isFilterVisible ? "setclose" : ""}`} id="filter_search">
                                    <Filter className="filter-icon" onClick={toggleFilterVisibility} />
                                    <span onClick={toggleFilterVisibility}>
                                        <img src="assets/img/icons/closes.svg" alt="img" />
                                    </span>
                                </Link>
                            </div>
                            <div className="form-sort stylewidth">
                                <Select className="img-select" classNamePrefix="react-select" options={options} placeholder="Sort by Date" />
                            </div>
                        </div>
                        {/* Filter */}
                        <div className={`card ${isFilterVisible ? "" : "d-none"}`} id="filter_inputs">
                            <div className="card-body pb-0">
                                <div className="row">
                                    <div className="col-lg-2 col-sm-6 col-12">
                                        <div className="form-group">
                                            <DatePicker
                                                selected={selectedDate}
                                                onChange={handleDateChange}
                                                dateFormat="dd/MM/yyyy"
                                                className="form-control form-control-sm"
                                                placeholderText="Choose Date"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-2 col-sm-6 col-12">
                                        <div className="form-group">
                                            <Select classNamePrefix="react-select" options={options} placeholder="Sort by Date" />
                                        </div>
                                    </div>
                                    <div className="col-lg-2 col-sm-6 col-12">
                                        <div className="form-group">
                                            <button type="button" className="btn btn-filters ms-auto">
                                                Apply Filters
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Table columns={columns} dataSource={ballotData} pagination={{ pageSize: 5 }} />
                    </div>
                </div>
            </div>

            {/* Add Ballot Modal */}
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Add Ballot</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form className="p-2">
                        <div className="form-group">
                            <label>Ballot Name</label>
                            <input type="text" className="form-control" placeholder="Enter Ballot Name" />
                        </div>
                        <div className="form-group">
                            <label>Status</label>
                            <select className="form-control">
                                <option value="Open">Open</option>
                                <option value="Closed">Closed</option>
                                <option value="In Progress">In Progress</option>
                            </select>
                        </div>
                        <div className="form-group">
                            <label>Region</label>
                            <input type="text" className="form-control" placeholder="Enter Region" />
                        </div>
                        <div className="form-group">
                            <label>Total Votes</label>
                            <input type="number" className="form-control" placeholder="Enter Total Votes" />
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <div className="d-flex justify-content-center w-100 gap-3">
                        <button type="button" className="btn btn-secondary" onClick={handleClose}>
                            Close
                        </button>
                        <button type="button" className="btn btn-primary">
                            Save
                        </button>
                    </div>
                </Modal.Footer>
            </Modal>

            {/* View Ballot Modal */}
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>View Ballot</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {/* Display the ballot details here */}
                    <h5>Ballot Name: Ballot 1</h5>
                    <p>Status: Open</p>
                    <p>Region: Region 1</p>
                    <p>Total Votes: 100</p>
                    {/* Example charts and analytics for the ballot */}
                    <div className="ballot-charts">
                        <div className="chart">
                            <h6>Pie Chart of Votes Distribution</h6>
                            {/* Placeholder for Pie Chart */}
                        </div>
                        <div className="chart">
                            <h6>Bar Chart of Votes over Time</h6>
                            {/* Placeholder for Bar Chart */}
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-secondary" onClick={handleClose}>
                        Close
                    </button>
                </Modal.Footer>
            </Modal>

        </div>
    );
};

export default Ballot;
