import React, { useState, useEffect } from 'react';
import { Card, Button, Modal, Form, ListGroup } from 'react-bootstrap';
import { Edit, Key } from 'react-feather';
import { useLocation } from 'react-router-dom';
import useFetch from '../../ApiClient/GetApi';
import { error_toaster, success_toaster } from '../../Utils/Toaster';
import Loader from '../../Utils/Loader';
import { PostApi } from '../../ApiClient/PostApi';

const UserDetails = () => {
    const location = useLocation();
    const { userId } = location.state;  // Get userId from location state
    const [loading, setLoading] = useState(false);

    const { apiData } = useFetch(`userDetails/${userId}`);
    const [userDetails, setUserDetails] = useState({
        id: '',
        name: '',
        email: '',
        phone: '',
        role: '',
        permissions: []
    });

    const [permissions, setPermissions] = useState([]);
    const [showEditModal, setShowEditModal] = useState(false);
    const [showPasswordModal, setShowPasswordModal] = useState(false);
    const [newPassword, setNewPassword] = useState('');

    useEffect(() => {
        if (apiData && apiData.data && apiData.data.userData) {
            // Assuming apiData is in the format you provided earlier
            setUserDetails({
                id: apiData.data.userData.id,
                name: apiData.data.userData.name,
                email: apiData.data.userData.email,
                phone: apiData.data.userData.phone,
                role: apiData.data.userData.role.name,
                permissions: apiData.data.userData.permissions || [] // Adjust if permissions are part of the role or another structure
            });
            setPermissions(apiData.data.userData.permissions || []);
        }
    }, [apiData]);

    const handleEditPermissions = () => {
        setUserDetails({ ...userDetails, permissions });
        setShowEditModal(false);
        alert('Permissions updated successfully!');
    };

    const handleChangePassword = async () => {
        if (newPassword.length >= 6) {
            try {
                setLoading(true)
                const response = await PostApi('/updatePassword', {
                    userId: userDetails.id,
                    newPassword: newPassword
                });
                if (response.data.status == "1") {
                    setLoading(false)
                    success_toaster(response.data.message)
                    setShowPasswordModal(false);
                } else {
                    setLoading(false);
                    error_toaster(response.data.message)
                }
            } catch (error) {
                setLoading(false);
                error_toaster(error.message);
            }
        } else {
            alert('Password must be at least 6 characters long.');
        }
    };

    const togglePermission = (permission) => {
        if (permissions.includes(permission)) {
            setPermissions(permissions.filter((perm) => perm !== permission));
        } else {
            setPermissions([...permissions, permission]);
        }
    };

    return (
        <div className="page-wrapper">
            {
                loading ? <Loader /> : <div className="container mt-4">
                    <h2 className="mb-4">User Details</h2>
                    <div className="row">
                        <div className="col-md-3 mb-3">
                            <Card>
                                <Card.Body>
                                    <Card.Title>Name</Card.Title>
                                    <Card.Text>{userDetails.name}</Card.Text>
                                </Card.Body>
                            </Card>
                        </div>
                        <div className="col-md-3 mb-3">
                            <Card>
                                <Card.Body>
                                    <Card.Title>Email</Card.Title>
                                    <Card.Text>{userDetails.email}</Card.Text>
                                </Card.Body>
                            </Card>
                        </div>
                        <div className="col-md-3 mb-3">
                            <Card>
                                <Card.Body>
                                    <Card.Title>Phone</Card.Title>
                                    <Card.Text>{userDetails.phone}</Card.Text>
                                </Card.Body>
                            </Card>
                        </div>
                        <div className="col-md-3 mb-3">
                            <Card>
                                <Card.Body>
                                    <Card.Title>Role</Card.Title>
                                    <Card.Text>{userDetails.role}</Card.Text>
                                </Card.Body>
                            </Card>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-6 mb-3">
                            <Card>
                                <Card.Body>
                                    <Card.Title>Permissions</Card.Title>
                                    <ListGroup>
                                        {permissions.map((permission, index) => (
                                            <ListGroup.Item key={index}>{permission.charAt(0).toUpperCase() + permission.slice(1)}</ListGroup.Item>
                                        ))}
                                    </ListGroup>
                                    <Button variant="primary" className="mt-3" onClick={() => setShowEditModal(true)}>
                                        <Edit className="me-2" /> Manage Permissions
                                    </Button>
                                    <Button variant="secondary" className="ms-2 mt-3" onClick={() => setShowPasswordModal(true)}>
                                        <Key className="me-2" /> Change Password
                                    </Button>
                                </Card.Body>
                            </Card>
                        </div>
                    </div>

                    {/* Edit Permissions Modal */}
                    <Modal show={showEditModal} onHide={() => setShowEditModal(false)}>
                        <Modal.Header closeButton>
                            <Modal.Title>Edit Permissions</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form>
                                {['read', 'write', 'execute'].map((permission) => (
                                    <Form.Check
                                        key={permission}
                                        type="checkbox"
                                        label={permission.charAt(0).toUpperCase() + permission.slice(1)}
                                        checked={permissions.includes(permission)}
                                        onChange={() => togglePermission(permission)}
                                    />
                                ))}
                            </Form>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => setShowEditModal(false)}>
                                Close
                            </Button>
                            <Button variant="primary" onClick={handleEditPermissions}>
                                Save Changes
                            </Button>
                        </Modal.Footer>
                    </Modal>

                    {/* Change Password Modal */}
                    <Modal show={showPasswordModal} onHide={() => setShowPasswordModal(false)}>
                        <Modal.Header closeButton>
                            <Modal.Title>Change Password</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form>
                                <Form.Group>
                                    <Form.Label>New Password</Form.Label>
                                    <Form.Control
                                        type="password"
                                        value={newPassword}
                                        onChange={(e) => setNewPassword(e.target.value)}
                                        placeholder="Enter new password"
                                    />
                                </Form.Group>
                            </Form>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => setShowPasswordModal(false)}>
                                Close
                            </Button>
                            <Button variant="primary" onClick={handleChangePassword}>
                                Change Password
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </div>
            }
        </div>
    );
};

export default UserDetails;
