// DateFormat.js

function formatDate(date) {
    if (!date) {
      return ""; // Return empty string if no date is provided
    }
  
    const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
    const formattedDate = new Intl.DateTimeFormat('en-US', options).format(new Date(date));
  
    return formattedDate;
  }
  
  export default formatDate;