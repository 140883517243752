import React, { useEffect, useState, useMemo } from "react";
import { Search } from "react-feather";
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Modal } from "react-bootstrap";
import Table from "../../core/pagination/datatable";
import PlusCircle from "feather-icons-react/build/IconComponents/PlusCircle";
import ReactQuill from 'react-quill'; // Import ReactQuill
import 'react-quill/dist/quill.snow.css'; // Import Quill styles
import { PostApi } from "../../ApiClient/PostApi";
import { error_toaster, success_toaster } from "../../Utils/Toaster";
import Loader from "../../Utils/Loader";
import useFetch from "../../ApiClient/GetApi";
import { IMAGE_URL } from "../../Utils/urls";

const News = () => {
    const [selectedDate, setSelectedDate] = useState(null);
    const [role, setRole] = useState(null);
    const [permissions, setPermissions] = useState([]);
    const [show, setShow] = useState(false);
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [image, setImage] = useState(null);
    const [edit, setEdit] = useState(false);
    const [loading, setLoading] = useState(false);
    const [news, setNews] = useState([]);

    const { apiData, reFetch } = useFetch("getNews");

    const handleClose = () => setShow(false);
    const handleShow = () => {
        setEdit(false);
        setShow(true);
        setTitle("");
        setDescription("");
        setImage(null);
    };

    const transformedData = useMemo(() => {
        if (apiData?.data?.data) {
            return apiData.data.data.map((newsItem) => ({
                id: newsItem.id,
                title: newsItem.title,
                image: newsItem.image,
                description: newsItem.description,
                createdAt: newsItem.createdAt,
                updatedAt: newsItem.updatedAt,
            }));
        }
        return [];
    }, [apiData]);
    useEffect(() => {
        setNews(transformedData)
    }, [apiData]);
    console.log(news)

    const handleDateChange = (date) => {
        setSelectedDate(date);
    };

    const handleImageChange = (event) => {
        setImage(event.target.files[0]);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        const formData = new FormData();
        formData.append('title', title);
        formData.append('description', description);
        if (image) formData.append('image', image); // Only append image if it's present
        setLoading(true);
        try {
            let response = await PostApi("addNews", formData);
            if (response.data.status === "1") {
                reFetch();
                success_toaster(response.data.message);
                setShow(false);
            } else {
                error_toaster(response.data.message);
            }
        } catch (error) {
            error_toaster("An error occurred while submitting the form.");
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        setRole(localStorage.getItem('role'));
        setPermissions(JSON.parse(localStorage.getItem('permissions')) || []);
    }, []);

    const columns = [
        {
            title: "Title",
            dataIndex: "title",
            sorter: (a, b) => a.RegionName.length - b.RegionName.length,
        },
        {
            title: "Image",
            dataIndex: "image",
            sorter: (a, b) => a.RegionName.length - b.RegionName.length,
            render: (status, record) => (
               <div>
                <img style={{height:"60px",width:"60px"}} src={IMAGE_URL+record.image} />
               </div>
            ),
        },
        {
            title: "Description",
            dataIndex: "description",
            sorter: (a, b) => a.RegionName.length - b.RegionName.length,
        },

    ];

    return (
        <div className="page-wrapper">
            {loading ? <Loader /> : (
                <div className="content">
                    <div className="page-header">
                        <div className="add-item d-flex">
                            <div className="page-title">
                                <h4>News Management</h4>
                                <h6>Manage News</h6>
                            </div>
                        </div>
                        <ul className="table-top-head"></ul>
                        <div className="page-btn">
                            {(role === "user" && permissions.includes("add_news")) || role === "super admin" ? (
                                <button onClick={handleShow} className="btn btn-added">
                                    <PlusCircle className="me-2" />
                                    Add New
                                </button>
                            ) : ""}
                        </div>
                    </div>

                    <div className="card table-list-card">
                        <div className="card-body">
                            <div className="table-top">
                                <div className="search-set">
                                    <div className="search-input">
                                        <input
                                            type="text"
                                            placeholder="Search"
                                            className="form-control form-control-sm formsearch"
                                        />
                                        <Link to="#" className="btn btn-searchset">
                                            <Search className="feather-search" />
                                        </Link>
                                    </div>
                                </div>
                            </div>

                            <div className="card" id="filter_inputs">
                                <div className="card-body pb-0">
                                    <div className="row">
                                        <div className="col-lg-12 col-sm-12">
                                            <div className="row">
                                                <div className="col-lg col-sm-6 col-12">
                                                    <div className="form-group">
                                                        <div className="input-groupicon">
                                                            <DatePicker
                                                                selected={selectedDate}
                                                                onChange={handleDateChange}
                                                                className="form-control datetimepicker"
                                                                placeholderText="Select Date"
                                                            />
                                                            <div className="addonset">
                                                                <img
                                                                    src="assets/img/icons/calendars.svg"
                                                                    alt="img"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1 col-sm-6 col-12 ms-auto">
                                                    <div className="form-group">
                                                        <Link
                                                            className="btn btn-filters ms-auto"
                                                            to="#"
                                                        >
                                                            <img
                                                                src="assets/img/icons/searchwhites.svg"
                                                                alt="img"
                                                            />
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="table-responsive">
                                <Table
                                    className="table  table-hover table-center mb-0 datatable"
                                    columns={columns}
                                    dataSource={news}
                                    // rowKey={(record) => record.id}
                                />
                            </div>
                        </div>
                    </div>

                    {/* Add/Update News Modal */}
                    <Modal show={show} onHide={handleClose} size="xl">
                        <Modal.Header closeButton>
                            <Modal.Title>
                                {edit ? "Update News" : "Add New News"}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <form className="p-2" onSubmit={handleSubmit}>
                                <div className="form-group">
                                    <label>Title</label>
                                    <input
                                        value={title}
                                        onChange={(e) => setTitle(e.target.value)}
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Title"
                                    />
                                </div>
                                <div className="form-group mt-3">
                                    <label>Description</label>
                                    <ReactQuill
                                        value={description}
                                        onChange={setDescription}
                                        className="form-control"
                                        placeholder="Enter Description"
                                        style={{ height: '300px' }} // Adjust height as needed
                                    />
                                </div>
                                <div className="form-group mt-3">
                                    <label>Image</label>
                                    <input
                                        type="file"
                                        className="form-control"
                                        onChange={handleImageChange}
                                    />
                                </div>
                                <div className="d-flex justify-content-center gap-3 mt-3">
                                    <button
                                        type="button"
                                        className="btn btn-secondary"
                                        onClick={handleClose}
                                    >
                                        Close
                                    </button>
                                    <button
                                        type="submit"
                                        className="btn btn-primary"
                                    >
                                        {edit ? "Update" : "Save"}
                                    </button>
                                </div>
                            </form>
                        </Modal.Body>
                    </Modal>
                </div>
            )}
        </div>
    );
};

export default News;
