import React, { useRef, useState } from "react";
import ImageWithBasePath from "../../../core/img/imagewithbasebath";
import { Link, useNavigate } from "react-router-dom";
import { all_routes } from "../../../Router/all_routes";
import { PostApi } from "../../../ApiClient/PostApi";
import { error_toaster, success_toaster } from "../../../Utils/Toaster";
import Loader from "../../../Utils/Loader";

const Resetpassword = () => {
  const route = all_routes;
  const otpRef = useRef([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const handleOtpChange = (e, index) => {
    const value = e.target.value;
    if (value.length === 1 && index < otpRef.current.length - 1) {
      otpRef.current[index + 1].focus();
    }
  };

  const otpInputStyle = {
    width: "50px",
    height: "50px",
    textAlign: "center",
    fontSize: "24px",
    border: "1px solid #ccc",
    borderRadius: "5px",
    outline: "none",
    transition: "border-color 0.2s",
  };

  const otpInputFocusStyle = {
    borderColor: "#007bff",
  };

  const otpGroupStyle = {
    display: "flex",
    gap: "10px",
    justifyContent: "space-between",
  };

  const submitOtp = async (e) => {
    e.preventDefault();

    const otp = otpRef.current.map(input => input.value).join("");

    if (otp.length < 4 || otpRef.current.some(input => input.value === "")) {
      alert("Please fill in all the OTP fields.");
      return;
    }

    try {
      setLoading(true)
      const response = await PostApi("submitOtp", { userId: localStorage.getItem("userId"), forgetPasswordId: localStorage.getItem("forgetRequestId"), otp: otp });
      console.log(response)
      if (response.data.status === "1") {

        success_toaster(response.data.message);
        setLoading(false);
        navigate("/reset-password-2");

      }
      else {
        error_toaster(response.data.message);
        setLoading(false)
      }

    }
    catch (error) {
      error_toaster("Something went wrong");
      setLoading(false);
    }

  };

  return (
    <div className="main-wrapper">
      {
        loading ? <Loader /> : <div className="account-content">
          <div className="login-wrapper reset-pass-wrap bg-img">
            <div className="login-content">
              <form onSubmit={submitOtp}>
                <div className="login-userset">
                  <div className="login-logo logo-normal">
                    <ImageWithBasePath src="assets/img/logo.png" alt="img" />
                  </div>
                  <Link to={route.dashboard} className="login-logo logo-white">
                    <ImageWithBasePath src="assets/img/logo-white.png" alt="img" />
                  </Link>
                  <div className="login-userheading">
                    <h3>Enter OTP</h3>
                    <h4>
                      Enter the 4-digit OTP sent to your email to reset your password
                    </h4>
                  </div>
                  <div className="form-login">
                    <label>Enter OTP</label>
                    <div style={otpGroupStyle}>
                      {[0, 1, 2, 3].map((index) => (
                        <input
                          key={index}
                          type="text"
                          style={otpInputStyle}
                          maxLength="1"
                          ref={(el) => (otpRef.current[index] = el)}
                          onChange={(e) => handleOtpChange(e, index)}
                          onFocus={(e) => (e.target.style.borderColor = otpInputFocusStyle.borderColor)}
                          onBlur={(e) => (e.target.style.borderColor = otpInputStyle.border)}
                          required
                        />
                      ))}
                    </div>
                  </div>
                  <div className="form-login">
                    <button type="submit" className="btn btn-login">
                      Submit OTP
                    </button>
                  </div>
                  <div className="signinform text-center">
                    <h4>
                      Return to{" "}
                      <Link to={route.signin} className="hover-a">
                        {" "}
                        login{" "}
                      </Link>
                    </h4>
                  </div>
                  <div className="my-4 d-flex justify-content-center align-items-center copyright-text">
                    <p>Copyright © 2023 DreamsPOS. All rights reserved</p>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      }
    </div>
  );
};

export default Resetpassword;
