import React, { useState } from "react";
import ImageWithBasePath from "../../../core/img/imagewithbasebath";
import { Link, useNavigate } from "react-router-dom";
import { all_routes } from "../../../Router/all_routes";
import { PostApi } from "../../../ApiClient/PostApi";
import { error_toaster, success_toaster } from "../../../Utils/Toaster";
import Loader from "../../../Utils/Loader";

const ResetpasswordTwo = () => {
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const route = all_routes;

  const handlePasswordChange = (e) => {
    setNewPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };

  const submitPasswordReset = async (e) => {
    e.preventDefault();

    if (newPassword !== confirmPassword) {
      error_toaster("Passwords do not match");
      return;
    }

    try {
      setLoading(true);
      const userId = localStorage.getItem("userId");
      const response = await PostApi("resetPassword", { userId, newPassword });
      console.log(response);
      if (response.data.status === "1") {
        success_toaster(response.data.message);
        setLoading(false);
        navigate(route.signin);
      } else {
        error_toaster(response.data.message);
        setLoading(false);
      }
    } catch (error) {
      error_toaster("Something went wrong");
      setLoading(false);
    }
  };

  const inputStyle = {
    width: "100%",
    padding: "10px",
    fontSize: "16px",
    borderRadius: "5px",
    border: "1px solid #ccc",
  };

  return (
    <div className="main-wrapper">
      {loading ? (
        <Loader />
      ) : (
        <div className="account-content">
          <div className="login-wrapper bg-img">
            <div className="login-content">
              <form onSubmit={submitPasswordReset}>
                <div className="login-userset">
                  <div className="login-logo logo-normal">
                    <ImageWithBasePath src="assets/img/logo.png" alt="img" />
                  </div>
                  <Link to={route.dashboard} className="login-logo logo-white">
                    <ImageWithBasePath
                      src="assets/img/logo-white.png"
                      alt="img"
                    />
                  </Link>
                  <div className="login-userheading">
                    <h3>Reset password?</h3>
                    <h4>
                      Enter New Password &amp; Confirm Password to get inside
                    </h4>
                  </div>
                  <div className="form-login">
                    <label>New Password</label>
                    <div className="pass-group">
                      <input
                        type="password"
                        className="pass-input"
                        style={inputStyle}
                        value={newPassword}
                        onChange={handlePasswordChange}
                        required
                      />
                      <span className="fas toggle-passworda fa-eye-slash" />
                    </div>
                  </div>
                  <div className="form-login">
                    <label>Confirm New Password</label>
                    <div className="pass-group">
                      <input
                        type="password"
                        className="pass-input"
                        style={inputStyle}
                        value={confirmPassword}
                        onChange={handleConfirmPasswordChange}
                        required
                      />
                      <span className="fas toggle-passworda fa-eye-slash" />
                    </div>
                  </div>
                  <div className="form-login">
                    <button type="submit" className="btn btn-login">
                      Change Password
                    </button>
                  </div>
                  <div className="signinform text-center">
                    <h4>
                      Return to{" "}
                      <Link to={route.signintwo} className="hover-a">
                        {" "}
                        login{" "}
                      </Link>
                    </h4>
                  </div>
                  <div className="my-4 d-flex justify-content-center align-items-center copyright-text">
                    <p>Copyright © 2023 DreamsPOS. All rights reserved</p>
                  </div>
                </div>
              </form>
            </div>
            <div className="login-img">
              <ImageWithBasePath
                src="assets/img/authentication/reset02.png"
                alt="img"
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ResetpasswordTwo;
