import React, { useEffect, useState, useRef } from "react";
import { Filter, Search, RotateCcw, Upload } from "react-feather";
import { Link } from "react-router-dom";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import Table from "../../core/pagination/datatable";

import { Pie, Bar } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip as ChartTooltip, Legend, CategoryScale, LinearScale, BarElement, Title } from 'chart.js';
import useFetch from "../../ApiClient/GetApi";
import { PostApi } from "../../ApiClient/PostApi";
import { error_toaster, success_toaster } from "../../Utils/Toaster";
import Loader from "../../Utils/Loader";

ChartJS.register(ArcElement, ChartTooltip, Legend, CategoryScale, LinearScale, BarElement, Title);

const Reports = () => {

    const [isFilterVisible, setIsFilterVisible] = useState(false);
    const [selectedDate, setSelectedDate] = useState(null);
    const [reportsData, setReportsData] = useState([]);
    const [show, setShow] = useState(false);
    const [selectedReport, setSelectedReport] = useState(null);
    const [loading, setLoading] = useState(false);
    const fileInputRef = useRef(null);

    const { apiData, reFetch } = useFetch("getReports");

    useEffect(() => {
        if (apiData?.data?.reports) {
            const transformedData = apiData.data.reports.map((report) => ({
                key: report.id,
                Region: report.region,
                RegionCode: report.regionCode,
                District: report.district,
                DistrictCode: report.districtCode,
                Constituency: report.consistuency,
                Area: report.area,
                AreaCode: report.areaCode,
                PollingStation: report.pollingStation,
                PollingStationCode: report.pollingStationCode,
                Voters: report.voters,
                CreatedDate: report.createdAt.split("T")[0],
            }));
            setReportsData(transformedData);
        }
    }, [apiData]);

    const handleClose = () => setShow(false);
    const handleShow = (report) => {
        setSelectedReport(report);
        setShow(true);
    };

    const toggleFilterVisibility = () => {
        setIsFilterVisible((prevVisibility) => !prevVisibility);
    };

    const handleDateChange = (date) => {
        setSelectedDate(date);
    };

    const handleFileChange = async (e) => {
        const file = e.target.files[0];
        if (!file) {
            error_toaster("Please select a file to upload.");
            return;
        }

        const formData = new FormData();
        formData.append("file", file);

        setLoading(true);
        const response = await PostApi("uploadReports", formData, true);
        if (response.data.status === "1") {
            reFetch();
            setLoading(false);
            success_toaster(response.data.message);
        } else {
            error_toaster(response.data.message);
            setLoading(false);
        }
    };

    const handleUploadClick = () => {
        fileInputRef.current.click();
    };

    const options = [
        { value: "sortByDate", label: "Sort by Date" },
        { value: "140923", label: "14 09 23" },
        { value: "110923", label: "11 09 23" },
    ];

    const columns = [
        {
            title: "Region",
            dataIndex: "Region",
            sorter: (a, b) => a.Region.length - b.Region.length,
        },
        {
            title: "Region Code",
            dataIndex: "RegionCode",
            sorter: (a, b) => a.RegionCode.length - b.RegionCode.length,
        },
        {
            title: "District",
            dataIndex: "District",
            sorter: (a, b) => a.District.length - b.District.length,
        },
        {
            title: "District Code",
            dataIndex: "DistrictCode",
            sorter: (a, b) => a.DistrictCode.length - b.DistrictCode.length,
        },
        {
            title: "Constituency",
            dataIndex: "Constituency",
            sorter: (a, b) => a.Constituency.length - b.Constituency.length,
        },
        {
            title: "Area",
            dataIndex: "Area",
            sorter: (a, b) => a.Area.length - b.Area.length,
        },
        {
            title: "Polling Station",
            dataIndex: "PollingStation",
            sorter: (a, b) => a.PollingStation.length - b.PollingStation.length,
        },
        {
            title: "Voters",
            dataIndex: "Voters",
            sorter: (a, b) => a.Voters - b.Voters,
        },
        {
            title: "Created Date",
            dataIndex: "CreatedDate",
            sorter: (a, b) => new Date(a.CreatedDate) - new Date(b.CreatedDate),
        },
        {
            title: "Action",
            dataIndex: "action",
            render: (_, record) => (
                <div className="action-table-data">
                    <div className="edit-delete-action">
                        <Link className="me-2 p-2" to="#" onClick={() => handleShow(record)}>
                            View
                        </Link>
                    </div>
                </div>
            ),
        },
    ];

    // Example data for the Pie Chart and Bar Chart
    const pieData = {
        labels: ['Candidate A', 'Candidate B', 'Candidate C'],
        datasets: [
            {
                label: 'Votes',
                data: [300, 200, 100],
                backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56'],
                hoverBackgroundColor: ['#FF6384', '#36A2EB', '#FFCE56'],
            },
        ],
    };

    const barData = {
        labels: ['Region 1', 'Region 2', 'Region 3'],
        datasets: [
            {
                label: 'Votes',
                data: [500, 400, 300],
                backgroundColor: 'rgba(75,192,192,0.4)',
                borderColor: 'rgba(75,192,192,1)',
                borderWidth: 1,
            },
        ],
    };

    return (
        <div className="page-wrapper">
            {loading ? <Loader /> : <div className="content">
                <div className="page-header">
                    <div className="add-item d-flex">
                        <div className="page-title">
                            <h4>Reports & Analytics</h4>
                            <h6>Generate and View Reports</h6>
                        </div>
                    </div>
                    <ul className="table-top-head">
                        <li>
                            <OverlayTrigger placement="top" overlay={<Tooltip id="pdf-tooltip">Pdf</Tooltip>}>
                                <Link to="#">
                                    <img src="assets/img/icons/pdf.svg" alt="img" />
                                </Link>
                            </OverlayTrigger>
                        </li>
                        <li>
                            <OverlayTrigger placement="top" overlay={<Tooltip id="excel-tooltip">Excel</Tooltip>}>
                                <Link to="#">
                                    <img src="assets/img/icons/excel.svg" alt="img" />
                                </Link>
                            </OverlayTrigger>
                        </li>
                        <li>
                            <OverlayTrigger placement="top" overlay={<Tooltip id="upload-tooltip">Upload</Tooltip>}>
                                <button className="btn btn-link" onClick={handleUploadClick}>
                                    <Upload />
                                </button>
                            </OverlayTrigger>
                        </li>
                        <li>
                            <OverlayTrigger placement="top" overlay={<Tooltip id="refresh-tooltip">Refresh</Tooltip>}>
                                <Link to="#">
                                    <RotateCcw />
                                </Link>
                            </OverlayTrigger>
                        </li>
                        
                    </ul>
                </div>

                {/* Reports list */}
                <div className="card table-list-card">
                    <div className="card-body">
                        <div className="table-top">
                            <div className="search-set">
                                <div className="search-input">
                                    <input type="text" placeholder="Search" className="form-control form-control-sm formsearch" />
                                    <Link to="#" className="btn btn-searchset">
                                        <Search className="feather-search" />
                                    </Link>
                                </div>
                            </div>
                            <div className="search-path">
                                <Link className={`btn btn-filter ${isFilterVisible ? "setclose" : ""}`} id="filter_search">
                                    <Filter className="filter-icon" onClick={toggleFilterVisibility} />
                                    <span onClick={toggleFilterVisibility}>
                                        <img src="assets/img/icons/closes.svg" alt="img" />
                                    </span>
                                </Link>
                            </div>
                            <div className="form-sort stylewidth">
                                <Select className="img-select" classNamePrefix="react-select" options={options} placeholder="Sort by Date" />
                            </div>
                        </div>
                        {/* Filter */}
                        <div className={`card ${isFilterVisible ? "" : "d-none"}`} id="filter_inputs">
                            <div className="card-body pb-0">
                                <div className="row">
                                    <div className="col-lg-2 col-sm-6 col-12">
                                        <div className="form-group">
                                            <DatePicker
                                                selected={selectedDate}
                                                onChange={handleDateChange}
                                                dateFormat="dd/MM/yyyy"
                                                className="form-control form-control-sm"
                                                placeholderText="Choose Date"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-2 col-sm-6 col-12">
                                        <div className="form-group">
                                            <Select classNamePrefix="react-select" options={options} placeholder="Sort by Date" />
                                        </div>
                                    </div>
                                    <div className="col-lg-2 col-sm-6 col-12">
                                        <div className="form-group">
                                            <button type="button" className="btn btn-filters ms-auto">
                                                Apply Filters
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Table columns={columns} dataSource={reportsData} pagination={{ pageSize: 5 }} />
                    </div>
                </div>
            </div>}

            {/* View Report Modal */}
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>View Report</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {selectedReport && (
                        <>
                            <h5>Region: {selectedReport.Region}</h5>
                            <p>Region Code: {selectedReport.RegionCode}</p>
                            <p>District: {selectedReport.District}</p>
                            <p>Constituency: {selectedReport.Constituency}</p>
                            <p>Polling Station: {selectedReport.PollingStation}</p>
                            <p>Voters: {selectedReport.Voters}</p>
                            <p>Created Date: {selectedReport.CreatedDate}</p>
                            {/* Example charts and tables for the report */}
                            <div className="report-charts">
                                <div className="chart">
                                    <h6>Pie Chart of Votes</h6>
                                    <Pie data={pieData} />
                                </div>
                                <div className="chart">
                                    <h6>Bar Chart of Votes by Region</h6>
                                    <Bar data={barData} />
                                </div>
                            </div>
                            <div className="report-summary">
                                <h6>Summary Table</h6>
                                {/* Placeholder for Summary Table */}
                            </div>
                        </>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-secondary" onClick={handleClose}>
                        Close
                    </button>
                </Modal.Footer>
            </Modal>

            {/* Hidden file input for uploading Excel */}
            <input
                type="file"
                ref={fileInputRef}
                style={{ display: "none" }}
                accept=".xlsx, .xls"
                onChange={handleFileChange}
            />
        </div>
    );
};

export default Reports;
