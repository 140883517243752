import React, { useState, useEffect } from "react";
import Table from "../../core/pagination/datatable";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Loader from "../../Utils/Loader";
import useFetch from "../../ApiClient/GetApi";
import 'bootstrap/dist/css/bootstrap.min.css';
import { IMAGE_URL } from "../../Utils/urls";
import { useLocation } from "react-router-dom";

const ResultDetails = () => {
    const location = useLocation();
    const [resultData, setResultData] = useState(null);
    const { apiData, loading } = useFetch(`resultDetails/${location?.state?.id}`);
    const [candidateList, setCandidateList] = useState([]);


    useEffect(() => {
        if (apiData && apiData.data) {
            setResultData(apiData.data.resultData);
        }
        if (apiData && apiData.data && apiData.data.pollingStationCandidates) {
            let formattedData = apiData.data.pollingStationCandidates.map((station) => ({
                candidateName: station?.candidate?.name,
                candidateType: station?.candidate?.candidatetype?.name,
                voteSecured: station?.voteSecured,
                party: station?.candidate?.party?.name,
                partyImage: station?.candidate?.party?.image,
            }));


            setCandidateList(formattedData);
        }
    }, [apiData]);

    if (loading) {
        return <Loader />;
    }

    if (!resultData) {
        return <div>No result data available</div>;
    }

    const {
        totalVoters,
        wrongVotes,
        voteCasts,
        winningCandidateVotes,
        candidate,

    } = resultData;

    const columns = [
        {
            title: "Candidate Name",
            dataIndex: "candidateName",
            sorter: (a, b) => a.PollingStationName.length - b.PollingStationName.length,
        },
        {
            title: "Candidate Type",
            dataIndex: "candidateType",
            sorter: (a, b) => a.PollingStationName.length - b.PollingStationName.length,
        },
        {
            title: "Party",
            dataIndex: "party",
            sorter: (a, b) => a.PollingStationCode.length - b.PollingStationCode.length,
            render: (status) => <span className="btn btn-success btn-sm" style={{ width: "90px" }}>{status}</span>,
        },
        {
            title: "Party Image",
            dataIndex: "partyImage",
            sorter: (a, b) => a.AreaName.length - b.AreaName.length,
            render: (status) => <img src={IMAGE_URL + status} className="btn btn-info btn-sm" style={{ width: "60px" }} />,
        },
        {
            title: "No. of Votes",
            dataIndex: "voteSecured",
            sorter: (a, b) => a.NoOfVoters - b.NoOfVoters,
        },



    ];

    return (
        <div className="page-wrapper">
            <ToastContainer />
            <div className="content">
                <div className="page-header">
                    <div className="add-item d-flex">
                        <div className="page-title">
                            <h4>Results Details</h4>
                            <h6>Details</h6>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row mb-4">
                        <div className="col-md-6">
                            <div className="card">
                                <div className="card-header  bg-warning">
                                    <h5 className="card-title">Result Summary</h5>
                                </div>
                                <div className="card-body">
                                    <p><strong>Total Votes:</strong> {totalVoters || "No Data"}</p>
                                    <p><strong>Votes Cast:</strong> {voteCasts || "No Data"}</p>
                                    <p><strong>Rejected Votes:</strong> {wrongVotes || "No Data"}</p>
                                    <p><strong>Winning Candidate&apos;s Votes:</strong> {winningCandidateVotes || "No Data"}</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="card">
                                <div className="card-header bg-warning">
                                    <h5 className="card-title">Winning Candidate</h5>
                                </div>
                                <div className="card-body text-center">
                                    <img
                                        src={candidate?.party?.image ? IMAGE_URL + candidate.party.image : "placeholder.jpg"}
                                        alt={candidate?.party?.name || "No Data"}
                                        className="img-fluid rounded-circle mb-3"
                                        style={{ width: "100px", height: "100px" }}
                                    />
                                    <h5>{candidate?.name || "No Data"}</h5>
                                    <p><strong>Party:</strong> {candidate?.party?.name || "No Data"}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-header">
                            <h5 className="card-title">Notes</h5>
                        </div>
                        <div className="card-body">
                            {
                                resultData.comment == null || resultData.comment == "null" ? "" : <p>{resultData.comment ?? resultData?.comment}</p>
                            }

                        </div>
                        {
                            resultData.file ? <div className="card-body">
                                <a href={IMAGE_URL + resultData?.file} className="btn btn-success">Download File</a>
                            </div> : ""
                        }

                    </div>
                    <div className="card">
                        <div className="card-header bg-warning">
                            <h5 className="card-title">Candidates in Polling Station</h5>
                        </div>
                        <div className="card-body">
                            <Table
                                columns={columns}
                                dataSource={candidateList}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ResultDetails;
