import { createStore } from 'redux';

// Example reducer (you'll replace this with your actual reducers)
const initialState = {
  value: 0
};

function rootReducer(state = initialState, action) {
  switch (action.type) {
    case 'INCREMENT':
      return { ...state, value: state.value + 1 };
    case 'DECREMENT':
      return { ...state, value: state.value - 1 };
    default:
      return state;
  }
}

// Create Redux store
const store = createStore(rootReducer);

export default store;
