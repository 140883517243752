import React, { useEffect, useState } from "react";
import { Filter, Search, Edit, Trash2, RotateCcw } from "react-feather";
import { Link } from "react-router-dom";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import Table from "../../core/pagination/datatable";
import PlusCircle from "feather-icons-react/build/IconComponents/PlusCircle";

const SystemSetting = () => {
  
    const [isFilterVisible, setIsFilterVisible] = useState(false);
    const [selectedDate, setSelectedDate] = useState(null);
    const [settingsData, setSettingsData] = useState([]);
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const toggleFilterVisibility = () => {
        setIsFilterVisible((prevVisibility) => !prevVisibility);
    };

    const handleDateChange = (date) => {
        setSelectedDate(date);
    };

    const generateRandomData = () => {
        const settingNames = ["Time-Zone", "User Permissions", "System Notifications"];
        const settingValues = ["UTC+1", "Admin", "Enabled"];
        const descriptions = [
            "Set the default time-zone for the system",
            "Manage user permissions and access levels",
            "Control system-wide notifications"
        ];

        return Array.from({ length: 10 }, (_, i) => ({
            key: i,
            SettingName: settingNames[i % settingNames.length],
            SettingValue: settingValues[i % settingValues.length],
            Description: descriptions[i % descriptions.length],
            CreatedDate: new Date().toISOString().split('T')[0],
        }));
    };

    useEffect(() => {
        setSettingsData(generateRandomData());
    }, []);

    const options = [
        { value: "sortByDate", label: "Sort by Date" },
        { value: "140923", label: "14 09 23" },
        { value: "110923", label: "11 09 23" },
    ];

    const columns = [
        {
            title: "Setting Name",
            dataIndex: "SettingName",
            sorter: (a, b) => a.SettingName.length - b.SettingName.length,
        },
        {
            title: "Setting Value",
            dataIndex: "SettingValue",
            sorter: (a, b) => a.SettingValue.length - b.SettingValue.length,
            render: (status) => <span className="btn btn-success btn-sm" style={{width:"90px"}}>{status}</span>,
        },
        {
            title: "Description",
            dataIndex: "Description",
            sorter: (a, b) => a.Description.length - b.Description.length,
        },
        {
            title: "Created Date",
            dataIndex: "CreatedDate",
            sorter: (a, b) => new Date(a.CreatedDate) - new Date(b.CreatedDate),
        },
        {
            title: "Action",
            dataIndex: "action",
            render: () => (
                <div className="action-table-data">
                    <div className="edit-delete-action">
                        <Link className="me-2 p-2" to="#" data-bs-toggle="modal" data-bs-target="#edit-units">
                            <Edit className="feather-edit" />
                        </Link>
                        <Link className="confirm-text p-2" to="#">
                            <Trash2 className="feather-trash-2" />
                        </Link>
                    </div>
                </div>
            ),
        },
    ];

    return (
        <div className="page-wrapper">
            <div className="content">
                <div className="page-header">
                    <div className="add-item d-flex">
                        <div className="page-title">
                            <h4>System Settings</h4>
                            <h6>Manage System Settings</h6>
                        </div>
                    </div>
                    <ul className="table-top-head">
                        <li>
                            <OverlayTrigger placement="top" overlay={<Tooltip id="pdf-tooltip">Pdf</Tooltip>}>
                                <Link to="#">
                                    <img src="assets/img/icons/pdf.svg" alt="img" />
                                </Link>
                            </OverlayTrigger>
                        </li>
                        <li>
                            <OverlayTrigger placement="top" overlay={<Tooltip id="excel-tooltip">Excel</Tooltip>}>
                                <Link to="#">
                                    <img src="assets/img/icons/excel.svg" alt="img" />
                                </Link>
                            </OverlayTrigger>
                        </li>
                        <li>
                            <OverlayTrigger placement="top" overlay={<Tooltip id="printer-tooltip">Printer</Tooltip>}>
                                <Link to="#">
                                    <i data-feather="printer" className="feather-printer" />
                                </Link>
                            </OverlayTrigger>
                        </li>
                        <li>
                            <OverlayTrigger placement="top" overlay={<Tooltip id="refresh-tooltip">Refresh</Tooltip>}>
                                <Link to="#">
                                    <RotateCcw />
                                </Link>
                            </OverlayTrigger>
                        </li>
                       
                    </ul>
                    <div className="page-btn">
                        <button onClick={handleShow} className="btn btn-added">
                            <PlusCircle className="me-2" />
                            Add New
                        </button>
                    </div>
                </div>

                {/* Settings list */}
                <div className="card table-list-card">
                    <div className="card-body">
                        <div className="table-top">
                            <div className="search-set">
                                <div className="search-input">
                                    <input type="text" placeholder="Search" className="form-control form-control-sm formsearch" />
                                    <Link to="#" className="btn btn-searchset">
                                        <Search className="feather-search" />
                                    </Link>
                                </div>
                            </div>
                            <div className="search-path">
                                <Link className={`btn btn-filter ${isFilterVisible ? "setclose" : ""}`} id="filter_search">
                                    <Filter className="filter-icon" onClick={toggleFilterVisibility} />
                                    <span onClick={toggleFilterVisibility}>
                                        <img src="assets/img/icons/closes.svg" alt="img" />
                                    </span>
                                </Link>
                            </div>
                            <div className="form-sort stylewidth">
                                <Select className="img-select" classNamePrefix="react-select" options={options} placeholder="Sort by Date" />
                            </div>
                        </div>
                        {/* Filter */}
                        <div className={`card ${isFilterVisible ? "" : "d-none"}`} id="filter_inputs">
                            <div className="card-body pb-0">
                                <div className="row">
                                    <div className="col-lg-2 col-sm-6 col-12">
                                        <div className="form-group">
                                            <DatePicker
                                                selected={selectedDate}
                                                onChange={handleDateChange}
                                                dateFormat="dd/MM/yyyy"
                                                className="form-control form-control-sm"
                                                placeholderText="Choose Date"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-2 col-sm-6 col-12">
                                        <div className="form-group">
                                            <Select classNamePrefix="react-select" options={options} placeholder="Sort by Date" />
                                        </div>
                                    </div>
                                    <div className="col-lg-2 col-sm-6 col-12">
                                        <div className="form-group">
                                            <button type="button" className="btn btn-filters ms-auto">
                                                Apply Filters
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Table columns={columns} dataSource={settingsData} pagination={{ pageSize: 5 }} />
                    </div>
                </div>
            </div>

            {/* Add Setting Modal */}
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Add Setting</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form className="p-2">
                        <div className="form-group">
                            <label>Setting Name</label>
                            <input type="text" className="form-control" placeholder="Enter Setting Name" />
                        </div>
                        <div className="form-group">
                            <label>Setting Value</label>
                            <input type="text" className="form-control" placeholder="Enter Setting Value" />
                        </div>
                        <div className="form-group">
                            <label>Description</label>
                            <textarea className="form-control" placeholder="Enter Description" rows="3"></textarea>
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <div className="d-flex justify-content-center w-100 gap-3">
                        <button type="button" className="btn btn-secondary" onClick={handleClose}>
                            Close
                        </button>
                        <button type="button" className="btn btn-primary">
                            Save
                        </button>
                    </div>
                </Modal.Footer>
            </Modal>

        </div>
    );
};

export default SystemSetting;
