import React, { useEffect, useState, useMemo } from "react";
import { Search, Edit } from "react-feather";
import { Link } from "react-router-dom";

import "react-datepicker/dist/react-datepicker.css";
import { Modal } from "react-bootstrap";
import Table from "../../core/pagination/datatable";
import PlusCircle from "feather-icons-react/build/IconComponents/PlusCircle";
import useFetch from "../../ApiClient/GetApi";
import { PostApi } from "../../ApiClient/PostApi";
import { error_toaster, success_toaster } from "../../Utils/Toaster";
import Loader from "../../Utils/Loader";
import { Trash2 } from "feather-icons-react/build/IconComponents";

const Session = () => {

    const [sessionData, setSessionData] = useState([]);

    const [show, setShow] = useState(false);
    const [name, setName] = useState("");
    const [value, setValue] = useState("");
    const [id, setId] = useState("");
    const [loading, setLoading] = useState(false);
    const [edit, setEdit] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => {
        setEdit(false);
        setShow(true);
        setName("");
        setValue("");
        setId("");
    };


    const { apiData, reFetch } = useFetch(`getAllSessions`);
    console.log(apiData?.data?.sessions)

    const transformedData = useMemo(() => {
        if (apiData?.data?.sessions) {
            return apiData?.data?.sessions.map((session) => ({
                key: session?.id,
                name: session?.name,
                value: session?.value,
                status: session?.status,
            }));
        }
        return [];
    }, [apiData]);

    useEffect(() => {
        setSessionData(transformedData);
    }, [transformedData]);

    const editModal = (obj) => {
        setName(obj.name);
        setValue(obj.value);
        setId(obj.key);
        setShow(true);
        setEdit(true);
    };

    const updateSession = async () => {
        setLoading(true);
        const response = await PostApi("updateSession", {
            id: id,
            name: name,
            value: value,
        });
        if (response.data.status === "1") {
            reFetch();
            success_toaster(response.data.message);
            setLoading(false);
            setShow(false);
        } else {
            error_toaster(response.data.message);
            setLoading(false);
        }
    };

    const addSession = async (e) => {
        e.preventDefault();
        if (name === "" || value === "") {
            error_toaster("Name and Value are required!");
            return false;
        }

        setLoading(true);
        const response = await PostApi("addSession", { name, value });
        if (response.data.status === "1") {
            reFetch();
            setLoading(false);
            setShow(false);
            success_toaster(response.data.message);
        } else {
            error_toaster(response.data.message);
            setLoading(false);
        }
    };

    const changeStatus = async (id) => {
        setLoading(true);
        const response = await PostApi("changeStatusSession", {
            id: id,
        });
        if (response.data.status === "1") {
            success_toaster(response.data.message);
            setLoading(false);
            reFetch();
        } else {
            error_toaster(response.data.message);
            setLoading(false);
        }
    };

    const columns = [
        {
            title: "Name",
            dataIndex: "name",
            sorter: (a, b) => a.name.length - b.name.length,
        },
        {
            title: "Value",
            dataIndex: "value",
            sorter: (a, b) => a.value.length - b.value.length,
        },
      
        {
            title: "Status Action",
            dataIndex: "status",
            render: (status, record) => (
                <span>
                    {status ? (
                        <button className="btn btn-sm btn-danger">Selected</button>
                    ) : (
                        <button onClick={() => changeStatus(record.key)} className="btn btn-sm btn-success">Select</button>
                    )}
                </span>
            ),
        },
        {
            title: "Action",
            dataIndex: "action",
            render: (text, record) => (
                <div className="action-table-data">
                    <div className="edit-delete-action">

                        <Link className="me-2 p-2">
                            <Edit onClick={() => editModal(record)} className="feather-edit" />
                        </Link>
                        <Link className="me-2 p-2">
                            <Trash2 className="feather-trash-2" />
                        </Link>



                    </div>
                </div>
            ),
        },
    ];

    return (
        <div className="page-wrapper">
            {loading ? (
                <Loader />
            ) : (
                <div className="content">
                    <div className="page-header">
                        <div className="add-item d-flex">
                            <div className="page-title">
                                <h4>Session Management</h4>
                                <h6>Manage Sessions</h6>
                            </div>
                        </div>
                        <div className="page-btn">

                            <button onClick={handleShow} className="btn btn-added">
                                <PlusCircle className="me-2" />
                                Add New
                            </button>

                        </div>
                    </div>

                    <div className="card table-list-card">
                        <div className="card-body">
                            <div className="table-top">
                                <div className="search-set">
                                    <div className="search-input">
                                        <input
                                            type="text"
                                            placeholder="Search"
                                            className="form-control form-control-sm formsearch"
                                        />
                                        <Link to="#" className="btn btn-searchset">
                                            <Search className="feather-search" />
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="table-responsive">
                                <Table
                                    className="table table-hover table-center mb-0 datatable"
                                    columns={columns}
                                    dataSource={sessionData}
                                    rowKey={(record) => record.key}
                                />
                            </div>
                        </div>
                    </div>

                    <Modal show={show} onHide={handleClose}>
                        <Modal.Header closeButton>
                            <Modal.Title>
                                {edit ? "Update Session" : "Add New Session"}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <form className="p-2">
                                <div className="form-group">
                                    <label>Name</label>
                                    <input
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Name"
                                    />
                                </div>
                                <div className="form-group">
                                    <label>Value</label>
                                    <input
                                        value={value}
                                        onChange={(e) => setValue(e.target.value)}
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Value"
                                    />
                                </div>

                                {loading ? (
                                    <div className="d-flex justify-content-center align-content-center">
                                        <Loader />
                                    </div>
                                ) : (
                                    <div className="d-flex justify-content-center gap-3 mt-3">
                                        <button
                                            type="button"
                                            className="btn btn-secondary"
                                            onClick={handleClose}
                                        >
                                            Close
                                        </button>
                                        {edit ? (
                                            <button
                                                onClick={updateSession}
                                                type="submit"
                                                className="btn btn-primary"
                                            >
                                                Update
                                            </button>
                                        ) : (
                                            <button
                                                onClick={addSession}
                                                type="submit"
                                                className="btn btn-primary"
                                            >
                                                Save
                                            </button>
                                        )}
                                    </div>
                                )}
                            </form>
                        </Modal.Body>
                    </Modal>
                </div>
            )}
        </div>
    );
};

export default Session;