import React, { useEffect, useState } from "react";
import { Filter, Edit, Trash2 } from "react-feather";
import { Link } from "react-router-dom";

import "react-datepicker/dist/react-datepicker.css";
import { Form, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import Table from "../../core/pagination/datatable";
import PlusCircle from "feather-icons-react/build/IconComponents/PlusCircle";
import useFetch from "../../ApiClient/GetApi";
import { PostApi } from "../../ApiClient/PostApi";
import { error_toaster, success_toaster } from "../../Utils/Toaster";
import Loader from "../../Utils/Loader";

const AdminManagement = () => {
   
    const [isFilterVisible, setIsFilterVisible] = useState(false);
    const [userData, setUserData] = useState([]);
    
    const [show, setShow] = useState(false);
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
   
    const [phone, setPhone] = useState("");
    const [password, setPassword] = useState("");
    const [id, setId] = useState("");
    const [loading, setLoading] = useState(false);
    const [edit, setEdit] = useState(false);
    const [selectedLevel, setSelectedLevel] = useState(null);
    const [selectedAuthorizations, setSelectedAuthorizations] = useState([]);

    const { apiData, reFetch } = useFetch("getAllAdmins");
    console.log(JSON.stringify(apiData))
    const { apiData: allLevels } = useFetch("getAllLevels");
    const { apiData: allAuthorizations } = useFetch("getAllAuthorizations");

    useEffect(() => {
        if (apiData?.data?.controllers) {
            const transformedData = apiData.data.controllers.map((region) => ({
                id: region.id,
                name: region.name,
                email: region.email,
                
                phoneNum: region.phone,
                phone:  region.phone,
                level:region?.level?.id,
                authorizations:region?.userauthorizations,
                role: "User"
            }));
            setUserData(transformedData);
        }
    }, [apiData]);

    useEffect(() => {
        if (allLevels?.data?.levels && selectedLevel) {
            const level = allLevels.data.levels.find(level => level.id === selectedLevel.value);
            if (level) {
                setSelectedAuthorizations(level.authorizations.map(auth => auth.id));
            }
        }
    }, [selectedLevel, allLevels]);

    const handleClose = () => setShow(false);
    const handleShow = () => {
        setShow(true);
        setName("");
        setEmail("");
        setPassword("");
       
        setPhone("");
        setId("");
        setEdit(false);
        setSelectedLevel(null);
        setSelectedAuthorizations([]);
    };

    const toggleFilterVisibility = () => setIsFilterVisible(!isFilterVisible);
    

    const handleLevelChange = (selectedOption) => {
        setSelectedLevel(selectedOption)
    };

    const handleAuthorizationChange = (event) => {

        const permissionId = parseInt(event.target.value);
        if (event.target.checked) {
            setSelectedAuthorizations([...selectedAuthorizations, permissionId]);
        } else {
            setSelectedAuthorizations(selectedAuthorizations.filter((id) => id !== permissionId));
        }

    };

    const addController = async () => {
        if(name == "" || email == "" || phone == "" || password== "" || !selectedLevel){
            error_toaster("Please filled the missing fields");
            return ;
        }
        if(selectedAuthorizations.length == 0){
            error_toaster("Please select permissions");
            return ;
        }
        setLoading(true);
        const response = await PostApi("addAdmin", {
            name,
            email,
          
            phone,
            password,
            selectedLevel: selectedLevel,
            selectedAuthorizations: selectedAuthorizations
        });
        if (response.data.status === "1") {
            setLoading(false);
            reFetch();
            setShow(false);
            success_toaster(response.data.message);
        } else {
            setLoading(false);
            error_toaster(response.data.message);
        }
    };

    const deleteUser = async (id) => {
        const response = await PostApi("deleteUser", { id });
        if (response.data.status === "1") {
            success_toaster(response.data.message);
            reFetch();
        } else {
            error_toaster(response.data.message);
        }
    };

    const editUser = (obj) => {
        console.log(obj)
        // Open the modal and populate fields with user data
        setEdit(true);
    
        // Set user details
        setName(obj.name);
        setEmail(obj.email);
        setPhone(obj.phone);
        
        setPassword(""); // Leave password field empty for security reasons
        setId(obj.id);
    
        // Set selected level if it exists
        if (obj.level) {
            setSelectedLevel(obj.level);
        } 
    
        // Set selected authorizations if they exist
        if (obj.authorizations.length  > 0) {
            const authIds = obj.authorizations.map(auth => auth.authorizationId);
           
            setSelectedAuthorizations(authIds);
        } else {
            setSelectedAuthorizations([]);
        }
    
        // Show the modal
        setShow(true);
    };
    console.log(selectedAuthorizations)
    
    

    const updateUser = async () => {
        setLoading(true);
        const response = await PostApi("updateAdmin", {
            id,
            name,
            email,
            phone,
            password,
            selectedLevel: selectedLevel,
            selectedAuthorizations: selectedAuthorizations
        });
        if (response.data.status === "1") {
            reFetch();
            setLoading(false);
            setShow(false);
            success_toaster(response.data.message);
        } else {
            setLoading(false);
            error_toaster(response.data.message);
        }
    };


    const columns = [
        {
            title: "Name",
            dataIndex: "name",
            sorter: (a, b) => a.name.length - b.name.length,
        },
        {
            title: "Email",
            dataIndex: "email",
            sorter: (a, b) => a.email.length - b.email.length,
        },
        {
            title: "Phone No.",
            dataIndex: "phone",
        },
        {
            title: "Role",
            dataIndex: "role",
            sorter: (a, b) => a.role.length - b.role.length,
            render: (status) => <span className="btn btn-success btn-sm" style={{ width: "90px" }}>{status}</span>,
        },
        {
            title: "Action",
            dataIndex: "action",
            render: (text, record) => (
                <div className="action-table-data">
                    <div className="edit-delete-action">
                        <div className="input-block add-lists"></div>
                        <Link className="me-2 p-2">
                            <Edit onClick={() => editUser(record)} className="feather-edit" />
                        </Link>
                        <Link className="confirm-text p-2" to="#">
                            <Trash2 onClick={() => deleteUser(record.id)} className="feather-trash-2" />
                        </Link>
                    </div>
                </div>
            ),
        },
    ];

    return (
        <div className="page-wrapper">
            {loading ? <Loader /> : (
                <div className="content">
                    <div className="page-header">
                        <div className="add-item d-flex">
                            <div className="page-title">
                                <h4>Users Management</h4>
                                <h6>Manage Users</h6>
                            </div>
                        </div>
                        <ul className="table-top-head">
                          
                          
                           
                            <li>
                                <button onClick={handleShow} className="btn btn-filters ms-2 me-2" style={{ color: "white", backgroundColor: "#019ff8", fontWeight: "600", padding: "7px 15px", borderRadius: "7px", fontSize: "12px" }}>
                                    <PlusCircle /> Add
                                </button>
                            </li>
                            <li>
                                <OverlayTrigger placement="top" overlay={<Tooltip id="filter-tooltip">Filter</Tooltip>}>
                                    <Link to="#" onClick={toggleFilterVisibility}>
                                        <Filter />
                                    </Link>
                                </OverlayTrigger>
                            </li>
                        </ul>
                    </div>
                    <div className="row">
                        {isFilterVisible && (
                            <div className="mb-4 filter-group-box">
                                <div className="row">
                                   
                                   
                                </div>
                            </div>
                        )}
                        <div className="col-md-12 d-flex">
                            <div className="card card-table flex-fill">
                                <div className="card-body">
                                    <div className="table-responsive">
                                        <Table columns={columns} dataSource={userData} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <Modal className="modal-lg" show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{edit ? "Edit User" : "Add User"}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Name</label>
                                <input type="text"   className="form-control shadow-sm"  placeholder="Name" value={name} onChange={(e) => setName(e.target.value)} />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Email</label>
                                <input type="text" className="form-control shadow-sm" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
                            </div>
                        </div>
                       
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Phone</label>
                                <input type="text" className="form-control shadow-sm" placeholder="Phone" value={phone} onChange={(e) => setPhone(e.target.value)} />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Password</label>
                                <input type="password" className="form-control shadow-sm" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Select Level</label>
                                <select className="form-control shadow-sm" defaultValue={selectedLevel} onChange={(e) => handleLevelChange(e.target.value)} name="" id="">
                                    <option value="">Select Designation</option>
                                    {
                                        allLevels?.data?.levels?.map((level) => (
                                            <option value={level.id} key={level.id}>{level.name}</option>
                                        ))
                                    }

                                </select>

                            </div>
                        </div>
                        <div className="col-md-12 my-3">
                            <div className="form-group">
                                <label>Select Authorizations</label>
                                <div className="d-flex flex-wrap">
                                    {allAuthorizations?.data?.authorizations?.map(permission => (
                                        <div key={permission.id} className="m-2"> <Form.Check
                                            key={permission.id}
                                            type="checkbox"
                                            label={permission.name}
                                            value={permission.id}
                                            checked={selectedAuthorizations?.includes(permission.id)}
                                            onChange={handleAuthorizationChange}
                                        /></div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button onClick={edit ? updateUser : addController} className="btn btn-primary">{edit ? "Update" : "Add"}</button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default AdminManagement;
