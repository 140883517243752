import React from "react";
export default function Loader() {
  return (
   <div>
     <div id="global-loader" >
      <div className="whirly-loader"> </div>
    </div>
   </div>
  );
}

export function Loader2() {
  return (
    <div>
    <div id="global-loader2" >
     <div className="whirly-loader"> </div>
   </div>
  </div>
  );
}
